import React from "react";
import { Grid } from "@mui/material";
import MagnifyingGlassIcon from "../../assets/Magnifying-Glass.gif";

export default function MagnifyingGlassPopUp({ additionalText }) {
  return (
    <Grid className="magnifyingGlassPopUp">
      <h4>
        We're in the process of determining the optimal number for your contact
        center
      </h4>
      <div className="magnifying-glass-icon">
        <img
          src={MagnifyingGlassIcon}
          alt="Magnifying Glass GIF"
          loading="lazy"
        />
        <h5 className="popup-note-text">
          {additionalText && additionalText} Please do not close or refresh this
          window
        </h5>
      </div>
    </Grid>
  );
}
