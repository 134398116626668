export const passwordValidation = (password) => {
  var regularExpression =
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  return regularExpression.test(password);
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z ]{2,}))$/
    );
};

export const specialCharacterValidation = (value) => {
  return /^[0-9a-zA-Z ]+$/.test(value);
};


export const accessUrlValidation = (value) => {
  return /^[0-9a-zA-Z\-\b ]+$/.test(value);
};

export const validateUserName = (userName) => {
  if (!userName) {
    return false;
  }

  if (userName.includes("@")) {
    const emailRegex = /^[a-zA-Z0-9_\-+.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(userName);
  } else {
    const usernameRegex = /^[a-zA-Z0-9_\-+\.]+$/;
    return usernameRegex.test(userName);
  }
};

export const validateAwsAccountNumber = (accountNumber) => {
  if (!accountNumber) {
    return false;
  }
  return /^[0-9]+$/.test(accountNumber) && accountNumber.length == 12;
};

export const validateAllowChatDomainURL = (domianURL) => {
  return /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{1,63}\.)+(com|in|info|net|org|co|digital|biz|online)$/.test(domianURL);

}