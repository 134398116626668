import * as React from "react";
import "./toggle.css";

export default function Toggle({ type, checked, required, label, disabled, handleOnChange, id }) {
  return (
    <div className="toggle-input">
      {label && (
        <p>
          {label}
        </p>
      )}
      <label className="switch">
        <input
          type={type}
          checked={checked}
          required={required}
          label={label}
          disabled={disabled}
          onChange={handleOnChange}
          id={id}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
}
