import * as React from "react";
import debounce from "debounce";
import "./button.css";

export default function Button({
  label,
  handleClick,
  className,
  id,
  disabled,
  icon,
  style,
}) {
  function onClick() {
    handleClick();
  }
  const handleDebounceClick = debounce(onClick, 300);
  const handleCallbackButton = React.useCallback(handleDebounceClick, [
    handleDebounceClick,
  ]);

  return (
    <div>
      <button
        className={className ? className : "global-button"}
        onClick={handleCallbackButton}
        id={id}
        disabled={disabled}
        style={style}
      >
        {label}
        {icon && icon}
      </button>
    </div>
  );
}
