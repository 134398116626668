export const getIntialCompanyDetails = {
  selectCompanyType: {
    techSupport: false,
    sales: false,
    internalHelpDesk: false,
    others: false,
  },
  launchStack:"",
  accountToken:"",
  awsToken:"",
  companyName: "",
  companyAccessUrl: "",
  companyAliasName: "",
  allowChatDomainURL: "",
  contactOptions: {
    incomingCalls: true,
    outgoingCalls: false,
    allowChat: false,
    // allowAnalytics: true,
 
  },
  awsAccountDetails: {
    // accountNumber: "",
     roleArn: "",
    region: "",
    installation: "",
  },
  // roleArn:"",
  accountNumber: "",
};

export const getIntialAdminDetails = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber:"",
  userName: "",
  password: "",
  confirmPassword: "",
  encryptedPassword:"",
  encryptedConfirmPassword: "",
  AlternateName:"",
  AlternateEmail:"",
};

export const getIntialOthersForm = {
  firstName: "",
  lastName: "",
  organizationName: "",
  email: "",
  phoneNumber: "",
  industry: "",
  useCase: "",
  description: "",
  countryCode: "",
};

export const getIntialAgentDetails = {
  selectAgentType: {
    addMannually: false,
    throughCsv: false,
  },
};
export const getIntialmannuallyDetails = {
  firstName: "",
  lastName: "",
  email: "",
  uniqueId: "",
  password: "",
  confirmPassword: "",
  phoneNumber: "",
  userRole: "",
  routingProfile: "",
};

export const getInitialSalesForceDetails = {
  apiUserPassword: "",
  consumerKey: "",
  consumerSecretKey: "",
  apiUserAccessToken: "",
  salesForceApiVersion: "",
  salesForceDomainURL: "",
};

export const getInitialCRMToolType = {
  selectCRMType: {
    salesForce: false,
    zendesk: false,
    seviceNow: false,
  },
};
export const getIntialClaimPhoneNumberDetails = {
  selectPhoneNumber: "",
};

export const getInitialHoursDetails = {
  timeZone: "",
  businessHours: "",
  callHandling: "",
  callRecording:"",
  description:"",
  greetingMessage: "",
  customPrompt:"",
  description: "",
  selectQueues: {
    salesQueue: false,
    technicalQueue: false,
    generalQueue: true,
    vIPQueue: false,
  },
};

export const getInitialQueueDetails = {
  queueName: "",
  queueDescription: "",
};

export const getInitialIntentDetails = {
  intentName: "",
  addPhrase: "",
  description: "",
};

export const getAdditionalQueueDetails = [];
export const getAdditionalAlternateMails=[];
export const getHomePageDetails = {
  uId:"",
}