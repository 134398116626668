export const generateConfigDaysPayload = (daysAndTimeList) => {
    let resultPayload = [];
    const startTimeList = daysAndTimeList[1][0].split(":");
    const endTimeList = daysAndTimeList[1][1].split(":");
    const startTimeHours = startTimeList[0];
    const startTimeMinutes = startTimeList[1];
    const endTimeHours = endTimeList[0];
    const endTimeMinutes = endTimeList[1];
    for (let i = 0; i < daysAndTimeList[0].length; i++) {
      let daysConfig = {
        Day: daysAndTimeList[0][i].toUpperCase(),
        StartTime: {
          Hours: parseInt(startTimeHours),
          Minutes: parseInt(startTimeMinutes),
        },
        EndTime: {
          Hours: parseInt(endTimeHours),
          Minutes: parseInt(endTimeMinutes),
        },
      };
      resultPayload.push(daysConfig);
    }
    return resultPayload;
  };