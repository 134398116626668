import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Home from "./components/home/home";
import OrganizationDetails from "./components/companyDetails";
import AdministratorDetails from "./components/administrator";
import ClaimPhoneNumber from "./components/claimPhoneNumber";
import CallHandlingOptions from "./components/selectQueues";
import AddAgents from "./components/addAgents";
import ConnectYourCRM from "./components/connectYourCRM";
import SuccessPage from "./components/successPage";
import NotFound from "./components/notFound";
import CsvUploadPage from "./components/addAgents/csvForm";
import SalesForce from "./components/connectYourCRM/salesForcePage";
import Cookie from "./components/footer/cookies";
import Terms from "./components/footer/terms";
import Privacy from "./components/footer/privacy";

function NavigationHandler() {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate("/", { replace: true });
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      sessionStorage.setItem('redirectToHome', 'true');
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('redirectToHome') === 'true') {
      sessionStorage.removeItem('redirectToHome');
      navigate("/", { replace: true });
    }
  }, [navigate]);

  return null;
}

export default function App() {
  return (
    <Router>
      <NavigationHandler />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/organization-details" element={<OrganizationDetails />} />
        <Route path="/claim-phone-number" element={<ClaimPhoneNumber />} />
        <Route path="/administrator-details" element={<AdministratorDetails />} />
        <Route path="/call-handling-options" element={<CallHandlingOptions />} />
        <Route path="/add-agents" element={<AddAgents />} />
        <Route path="/connect-your-crm" element={<ConnectYourCRM />} />
        <Route path="/success-page" element={<SuccessPage />} />
        <Route path="/bulk-user-creation" element={<CsvUploadPage />} />
        <Route path="/sales-force-form" element={<SalesForce />} />
        <Route path="/cookies" element={<Cookie />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
