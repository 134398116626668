import * as React from "react";
import _ from "lodash";
import { Grid, Snackbar } from "@mui/material";
import MainTheme from "../mainTemplate";
import {
  companyDetailsContext,
  adminDetailsContext,
  claimPhoneNumberContext,
  contactFlowContext,
  salesForceStatusContext,
} from "../../context";
import svg from "../../assets/Confetti Gif 2.gif";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import "./successPage.css";
import { Height } from "@mui/icons-material";

export default function SuccessPage() {
  const companyDetails = React.useContext(companyDetailsContext);
  const adminDetails = React.useContext(adminDetailsContext);
  const claimPhoneNumber = React.useContext(claimPhoneNumberContext);
  const contactFlowDetails = React.useContext(contactFlowContext);
  const salesForceStatus = React.useContext(salesForceStatusContext);
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    label: "",
  });
  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => {
    setState({ ...newState, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const userFields = React.useMemo(
    () => [
      {
        label: "Access-URL",
        value: companyDetails.companyAccessUrl
          ? companyDetails.companyAccessUrl
          : "",
      },
      {
        label: "Username",
        value: adminDetails.userName ? adminDetails.userName : "",
      },
      {
        label: "Password",
        value: adminDetails.password ? adminDetails.password : "",
      },
    ],
    [
      adminDetails.password,
      adminDetails.userName,
      companyDetails.companyAccessUrl,
    ]
  );
  const [copiedStates, setCopiedStates] = React.useState(
    Array(userFields.length).fill(false)
  );
  const copyToClipboard = (index, text, label) => {
    const tempInput = document.createElement("input");
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    const newCopiedStates = [...copiedStates];
    newCopiedStates[index] = true;
    setCopiedStates(newCopiedStates);

    for (let i = 0; i < newCopiedStates.length; i++) {
      if (i !== index) {
        newCopiedStates[i] = false;
      }
    }
    setCopiedStates(newCopiedStates);
    handleClick({ vertical: "bottom", horizontal: "right", label: label });
  };

  function reverseString(str) {
    const reversedString = str.split("").reduce((acc, char) => char + acc, "");
    return reversedString;
  }

  const phoneNumberDisplay = (str) => {
    const reversedString = reverseString(str);
    const reverseString1 = reversedString.substr(10, reversedString.length);
    const reverseString2 = reversedString.substr(0, 10);
    const countryCode = reverseString(reverseString1);
    const mobileNumber = reverseString(reverseString2);
    const resultString = countryCode + " " + mobileNumber;
    return resultString;
  };

  return (
    <MainTheme>
      <Grid container spacing={2} className="success-page-container">
        <Grid item md={12} xs={12} className="success-title-container">
          <h1 className="success-title">Congratulations</h1>
        </Grid>
        <Grid item md={12} xs={12} className="gif-container">
          <img src={svg} alt="Success-gif" loading="lazy" />
          <h3 className="success-page-text">
            Your contact center has been successfully launched
          </h3>
          <p className="phonenumber-text">
            Call your new contact center number
          </p>
          <p
            className="phone-number"
            onClick={() =>
              copyToClipboard(
                0,
                `${
                  claimPhoneNumber.selectPhoneNumber
                    ? claimPhoneNumber.selectPhoneNumber
                    : ""
                }`,
                "Phone Number"
              )
            }
          >
            <PhoneRoundedIcon className="phone-icon" />
            {claimPhoneNumber.selectPhoneNumber &&
              phoneNumberDisplay(claimPhoneNumber.selectPhoneNumber)}
            <ContentCopyRoundedIcon
              onClick={() =>
                copyToClipboard(
                  99,
                  `${
                    claimPhoneNumber.selectPhoneNumber
                      ? claimPhoneNumber.selectPhoneNumber
                      : ""
                  }`,
                  "Phone Number"
                )
              }
              className="copy-icon-small"
            />
          </p>
        </Grid>
        {userFields.map((item, index) => (
          <Grid item xs={12} className="user-info-container">
            <Grid container key={index} spacing={1} className="user-info-div">
              <Grid item xs={2.5} className="user-label-container">
                <p className="user-info-label">{item.label}</p>
              </Grid>
              <Grid item xs={7.5} className="user-value-container">
                <p className="user-info-value">{item.value}</p>
              </Grid>
              <Grid
                item
                xs={2}
                className="copyIcon"
                onClick={() => copyToClipboard(index, item.value, item.label)}
              >
                <ContentCopyRoundedIcon />
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}className="user-message-display" >
          {!_.isEmpty(contactFlowDetails.voiceMailPayload) &&
            !salesForceStatus.status && (
              <p className="Success-Msg">
                Voicemail feature will be enabled within 48-72 business hours
              </p>
            )}
          {salesForceStatus.status &&
            _.isEmpty(contactFlowDetails.voiceMailPayload) && (
              <p className="Success-Msg">
                Salesforce feature will be enabled within 48-72 business hours
              </p>
            )}
          {!_.isEmpty(contactFlowDetails.voiceMailPayload) &&
            salesForceStatus.status && (
              <p className="Success-Message">
                Salesforce and Voicemail feature will be enabled within 48-72
                business hours
              </p>
            )}
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={`${state.label} Copied`}
        key={vertical + horizontal}
      />
    </MainTheme>
  );
}