import dayjs from "dayjs";
import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Button from "../../utilities/button";
import "./selectQueues.css";

export default function CustomHours({ handleClose, setCustomHours, hoursConfigList }) {
  const [customHoursDays, setCustomHoursDays] = React.useState({});
  const [errorMessage, setErrorMessage] = React.useState("");

  
  React.useEffect(() => {
    if (hoursConfigList.length > 0) {
      const defaultCustomHoursDays = hoursConfigList.reduce((acc, item) => {
        const id = item.Day.toLowerCase();
        const day = item.Day.toUpperCase();
        const startTime = { Hours: item.StartTime.Hours, Minutes: item.StartTime.Minutes };
        const endTime = { Hours: item.EndTime.Hours, Minutes: item.EndTime.Minutes };
        return { ...acc, [id]: { Day: day, StartTime: startTime, EndTime: endTime } };
      }, {});
      setCustomHoursDays(defaultCustomHoursDays);
    }
  }, [hoursConfigList]);

  const dateFormatterInHHmm = (event) => {
    let hours = new Date(event["$d"]).getHours();
    let minutes = new Date(event["$d"]).getMinutes();
    if (JSON.stringify(hours).length === 1) {
      hours = "0" + JSON.stringify(hours);
    } else {
      hours = JSON.stringify(hours);
    }
    if (JSON.stringify(minutes).length === 1) {
      minutes = "0" + JSON.stringify(minutes);
    } else {
      minutes = JSON.stringify(minutes);
    }
    let result = [hours, minutes];
    return result;
  };

  const handleFromTimeChange = (event, id, day) => {
    const time = dateFormatterInHHmm(event);
    const resultObj = {
      Day: day.toUpperCase(),
      StartTime: {
        Hours: parseInt(time[0]),
        Minutes: parseInt(time[1]),
      },
    };
    if (Object.keys(customHoursDays).includes(id)) {
      customHoursDays[id].StartTime = resultObj.StartTime;
    } else {
      setCustomHoursDays((prevDetails) => ({
        ...prevDetails,
        [id]: { ...prevDetails[id], ...resultObj },
      }));
    }
    setErrorMessage(null);
  };

  const handleToTimeChange = (event, id, day) => {
    const time = dateFormatterInHHmm(event);
    const resultObj = {
      Day: day.toUpperCase(),
      EndTime: {
        Hours: parseInt(time[0]),
        Minutes: parseInt(time[1]),
      },
    };
    let keys = Object.keys(customHoursDays);
    if (keys.includes(id)) {
      customHoursDays[id].EndTime = resultObj.EndTime;
    } else {
      setCustomHoursDays((prevDetails) => ({
        ...prevDetails,
        [id]: { ...prevDetails[id], ...resultObj },
      }));
    }
    setErrorMessage(null);
  };

  const parseTime = (timeObj) => {
    const { Hours, Minutes } = timeObj;
    const date = new Date();
    date.setHours(Hours);
    date.setMinutes(Minutes);
    return date;
  };


  const defaultCustomHoursFields = React.useMemo(
    () => [
      {
        id: "sunday",
        day: "Sunday",
        startTime: "",
        endTime: "",
        onFromTimeChange: handleFromTimeChange,
        onToTimeChange: handleToTimeChange,
      },
      {
        id: "monday",
        day: "Monday",
        startTime: "",
        endTime: "",
        onFromTimeChange: handleFromTimeChange,
        onToTimeChange: handleToTimeChange,
      },
      {
        id: "tuesday",
        day: "Tuesday",
        startTime: "",
        endTime: "",
        onFromTimeChange: handleFromTimeChange,
        onToTimeChange: handleToTimeChange,
      },
      {
        id: "wednesday",
        day: "Wednesday",
        startTime: "",
        endTime: "",
        onFromTimeChange: handleFromTimeChange,
        onToTimeChange: handleToTimeChange,
      },
      {
        id: "thursday",
        day: "Thursday",
        startTime: "",
        endTime: "",
        onFromTimeChange: handleFromTimeChange,
        onToTimeChange: handleToTimeChange,
      },
      {
        id: "friday",
        day: "Friday",
        startTime: "",
        endTime: "",
        onFromTimeChange: handleFromTimeChange,
        onToTimeChange: handleToTimeChange,
      },
      {
        id: "saturday",
        day: "Saturday",
        startTime: "",
        endTime: "",
        onFromTimeChange: handleFromTimeChange,
        onToTimeChange: handleToTimeChange,
      },
    ],
    [customHoursDays]
  );
  
  const customHoursFields = React.useMemo(
    () => {
      if (hoursConfigList.length === 0) {
        return defaultCustomHoursFields;
      } else {
        return hoursConfigList.map((item) => ({
          id: item.Day.toLowerCase(),
          day: item.Day.charAt(0).toUpperCase() + item.Day.slice(1).toLowerCase(),
          startTime: parseTime(item.StartTime),
          endTime: parseTime(item.EndTime),
          onFromTimeChange: handleFromTimeChange,
          onToTimeChange: handleToTimeChange,
        }));
      }
    },
    [hoursConfigList]
  );

  const handleSave = () => {
    let missingEndDays = [];
    customHoursFields.forEach((day) => {
      const { id } = day;
      if (customHoursDays[id]?.StartTime && !customHoursDays[id]?.EndTime) {
        missingEndDays.push(day.day);
      }
    });    
    if (missingEndDays.length > 0) {
      setErrorMessage(
        `Please select end time for ${missingEndDays.join(", ")}.`
      );
      return;
    }
    let isAnyStartTimePresent = Object.values(customHoursDays).some(day => day.StartTime);
    if (!isAnyStartTimePresent) {
        setErrorMessage(
            "Before proceeding, please select start time and end time."
        );
        return;
    }
    setErrorMessage("");
    let resultArray = [];
    let values = Object.values(customHoursDays);
    Array.prototype.push.apply(resultArray, values);
    setCustomHours(resultArray);
    handleClose();
  };

  return (
    <Grid container spacing={2} className="custom-hour-container">
      <div className="custom-hour-form-container">
        {customHoursFields.map((item, index) => (
          <Grid
            item
            xs={12}
            key={index}
            container
            spacing={1}
            className="custom-hour-days-container"
          >
            <Grid item xs={2.5}>
              <Typography>{item.day}</Typography>
            </Grid>
            <Grid item xs={4.5}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    className="time-field-container"
                    label="Start time"
                    value={item.startTime ? dayjs(item.startTime) : null}
                    onChange={(event) =>
                      item.onFromTimeChange(event, item.id, item.day)
                    }
                    views={["hours", "minutes"]}
                    format="HH:mm"
                    ampm={false}
                    timeSteps={{ minutes: 1 }}
                    slotProps={{
                      textField: { size: "small", placeholder: "HH:MM" },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4.5}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    className="time-field-container"
                    label="End time"
                    disabled={!customHoursDays[item.id]?.StartTime} // Disable end time if start time is not selected
                    value={item.endTime ? dayjs(item.endTime) : null}
                    onChange={(event) =>
                      item.onToTimeChange(event, item.id, item.day)
                    }
                    views={["hours", "minutes"]}
                    format="HH:mm"
                    ampm={false}
                    id={item.toId}
                    // minTime={
                    //   customHoursDays[item.id]?.StartTime
                    //     ? dayjs().hour(customHoursDays[item.id]?.StartTime.Hours)
                    //     .minute(customHoursDays[item.id]?.StartTime.Minutes)
                    //     .add(1, 'minute')
                    //     : null
                    // }
                    timeSteps={{minutes: 1}}
                    slotProps={{
                      textField: { size: "small", placeholder: "HH:MM" },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        ))}
      </div>
      <div className="custom-hour-button-container">
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <Button label={"Save"} handleClick={handleSave} />
      </div>
    </Grid>
  );
}