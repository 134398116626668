import React from "react";
import "./styles.css";
const Terms = () => {
  return (
    <div class="content-container">
      <h1>Terms and Conditions</h1>
      <h2>Terms of Service</h2>
      <h3>1. Acceptance of Terms</h3>
      These Terms of Service constitute a legal agreement between you governing
      your use of the contact centre service provided by ConnectGen, Inc
      ("Company", "We", "Us", or "Our"). By accessing or using the contact
      centre service, you agree to comply with and be bound by these terms.
      <br />
      <h3>2. Description of Service</h3>
      Our contact centre service enables you to launch and operate contact
      centres tailored to Your specific requirements. You may select various
      options such as the number of agents, features, integrations, and
      customization preferences for your contact centre instance.
      <br />
      <h3>3. Account Registration</h3>
      To use our contact centre service, you must create an account on our
      platform. You agree to provide accurate and complete information during
      the account registration process. You are responsible for maintaining the
      security of your account credentials and for all activities that occur
      under your account.
      <h3>4. Use of Service</h3>
      You agree to use the contact centre service in compliance with all
      applicable laws and regulations. You are solely responsible for the
      content you transmit or store using the service. You must not use the
      service to engage in any illegal, fraudulent, or unauthorized activities.
      <h3>5. Intellectual Property</h3>
      All intellectual property rights in the contact centre service, including
      software, trademarks, and copyrights, belong to the ConnectGen, Inc. You
      are granted a limited, non-exclusive, non-transferable license to use the
      service for the duration of your subscription.
      <h3>Privacy</h3>
      We are committed to protecting the privacy of your information. Our
      Privacy Policy outlines how we collect, use, and disclose your
      information. By using our contact centre service, you consent to the
      collection, use and disclosure of your information as described in the
      Privacy Policy.
      <h3>7. Limitation of Liability</h3>
      To the fullest extent permitted by law, the company shall not be liable
      for any indirect, incidental, special, consequential, or punitive damages
      or any loss of profits or revenues, whether incurred directly or
      indirectly, arising out of the use of or inability to use the contact
      centre service.
      <h3>8. Termination</h3>
      The Company reserves the right to suspend or terminate your account and
      access to the contact centre service at any time, with or without cause,
      and without prior notice.
      <h3>9. Modification of Terms</h3>
      We reserve the right to modify these Terms at any time. Changes will
      become effective upon posting of the modified terms on our website. You
      are responsible for regularly reviewing the terms. Continued use of the
      contact centre service after any such changes constitutes acceptance of
      the modified terms.
      <h3>10. Governing Law</h3>
      These terms shall be governed by and construed in accordance with the laws
      of your jurisdiction. Any disputes arising out of or relating to these
      terms shall be subject to the exclusive jurisdiction of the courts.
      <h3>11. Contact Information</h3>
      If you have any questions or concerns about these terms, please contact us
      at rapidconnect-support@connectgenus.com & +1 (866)-983-0491
      <h3>12. Usage of Technology</h3>
      The contact centre service provided by the company which utilizes
      technology powered by Amazon Web Services (AWS) and other third-party
      providers. By using our service, Customers acknowledge and agree to abide
      by the terms of service and acceptable use policies of AWS and any other
      third-party providers whose technology is utilized in the provision of the
      service.
      <h3>13. AWS Services</h3>
      The contact centre service may utilize various AWS services, customers
      agree to comply with the AWS Service Terms available at
      (aws.amazon.com/legal/) and any additional terms and conditions specified
      by AWS for the use of their services.
      <h3>14. Community Standards</h3>
      We are committed to maintaining a positive and respectful community of
      users of our contact centre service.
      <br />
      Customers agree to abide by the following community standards: <br />
      •Respect: Treat other users with respect and refrain from engaging in any
      behaviour that is abusive, harassing, or discriminatory.
      <br />
      • Compliance: Adhere to all applicable laws, regulations, and these terms
      of service while using the service.
      <br />
      • Integrity: Do not engage in any fraudulent, deceptive, or misleading
      activities.
      <br />
      • Security: Take reasonable measures to protect the security of your
      account and the service, including safeguarding your credentials and
      promptly reporting any security vulnerabilities or incidents to the
      company.
      <h3>15. Monitoring and Enforcement</h3>
      The Company reserves the right to monitor customer usage of the contact
      centre service to ensure compliance with these terms and applicable laws
      and regulations. We may take appropriate enforcement actions, including
      but not limited to suspension or termination of customer accounts, in
      cases of violation of these terms or community standards.
      <h3>16. Reporting Violations</h3>
      If you believe that another user has violated these terms or community
      standards, please report the violation to us immediately by contacting:
      rapidconnect-support@connectgenus.com & +1 (866)-983-0491. We will
      investigate the reported violation and take appropriate action as
      necessary.
      <h3>17. Feedback</h3>
      We welcome your feedback and suggestions regarding the contact centre
      service. By providing feedback, you grant the company a perpetual,
      irrevocable, worldwide, royalty-free license to use, modify, adapt,
      publish, translate, distribute, and display such feedback for any purpose
      without compensation or attribution.
      <h3>18. Severability</h3>
      If any provision of these terms is held to be invalid or unenforceable,
      the remaining provisions shall remain in full force and effect. The
      invalid or unenforceable provision shall be replaced by a valid provision
      that most closely matches the intent of the original provision.
    </div>
  );
};

export default Terms;