import React from "react";
import "./styles.css";
const Privacy = () => {
  return (
    <div class="content-container">
        <h1>Privacy Policy</h1>
        <h3>What is privacy policy and what does it cover?</h3>
        We take your privacy seriously and work hard to keep your personal information secure. This Privacy Policy explains how we collect, use, share, and protect the information gathered when you utilize our contact centre launch product. Our platform uses AWS services and Amazon Connect to offer customers a smooth and efficient solution for establishing contact centres.The policy also covers information collected from partners and third parties. It aims to provide transparency and empower users to understand and control their privacy when using our service. The Privacy Policy also lets you know your rights.
        The information that you give us when you sign up for creating contact centre and create a profile/admin, such as your email address or phone number.
        When you get in touch with our contact centre, we may collect your name, contact details like email and phone number, and any other information you share willingly during your interactions.
        <br></br>
        Here's the information we collect:
        <br></br>
        1.Personal data: When you sign up for launching contact centre, we collect various types of personal information to create and customize your contact centre instance. This information may include:
Name
<br></br>
Email address.
<br></br>
Company name.
<br></br>
Payment information (if applicable).
<br></br>
Contact details.
<br></br>
Call routing preferences.
<br></br>
IVR scripts.
<br></br>
Agent scripts.
<br></br>
Other customization options chosen during configuration.
<br></br>
2.Configuration Data: We collect configuration data provided by you during the setup process, including but not limited to:
<br></br>
AWS account information.
<br></br>
Amazon Connect instance configuration.
<br></br>
Integration settings with other systems (e.g., CRM software)
<br></br>
3. Usage Data: Information about your usage patterns, interactions with the platform, and performance metrics may be collected for analytics and service improvement purposes.
<br></br>
<h3>How do we use your information?</h3>
We use the information collected for the following purposes:
<br></br>
    1 Providing Services: To set up and deliver the contact centre services you requested, including technical support and troubleshooting.
    <br></br>
    2 Improving Our Product: To analyse usage patterns, identify areas for improvement, and enhance the functionality of our product.
    <br></br>
    3 Communications: To communicate with you regarding your account, product updates, service announcements, and promotional offers.
    <br></br>
    4 Legal Compliance: To comply with legal obligations, respond to lawful requests, and protect our rights and the rights of others.
    Utilization of AWS Services:
    <br></br>
    Our contact centre product is built on AWS infrastructure and uses various AWS services to ensure scalability, reliability, and security. We follow AWS policies and best practices to protect your information: 
    Security: We use AWS security features like Identity and Access Management (IAM), encryption, and network controls to keep your data safe from unauthorized access, data breaches, and other threats. 
    Compliance: We comply with AWS data protection policies, including GDPR, HIPAA, and other regulations. We manage data residency and compliance according to AWS standards. <br></br>
    Scalability: AWS services allow us to dynamically scale our infrastructure to meet changing workload demands, ensuring optimal performance and reliability for our customers. <br></br>
    Reliability: We utilize AWS services like Amazon Relational Database Service to provide a highly available and fault-tolerant database solution, ensuring the reliability of our contact centre platform.<br></br>
    <h3>Data Security</h3>
    We take the security of your data very seriously and follow industry best practices: 
    <br></br>
Encryption: We encrypt data during transmission and storage using standard protocols like SSL/TLS and tools like AWS Key Management Service. This prevents unauthorized access and interception.
<br></br>
Access Controls: Only authorized personnel can access the data, and strict controls are in place to prevent misuse.
<br></br> 
Data Residency: We store customer data in AWS regions that comply with your requirements, following AWS data residency policies. 
<br></br>
Data Backup and Recovery: We regularly back up data to protect against loss and have robust disaster recovery measures to ensure business continuity.
<br></br>
<h3>How can you manage or delete your information and exercise your rights?</h3>
You have the right to see and download the information we have about you. You can use the settings to manage your privacy preferences. If you want, you can delete your account or specific account details. We provide tools for you to view, manage, download, and delete your information. You can access these tools below or through the settings of the products you use. You may also have other privacy rights under the law.<br></br>
<h3>Retention of Your Information </h3>
We keep your information for as long as we need to provide our services or features. However, you can ask us to delete your information, and we'll do that unless we're legally required to keep it. Here are some of the things we consider when deciding how long to keep your information: 
<br></br>
Whether we need it to make our products work properly. 
<br></br>
How long specific features require us to keep it.
<br></br>
Any legal requirements for us to hold onto it. 
<br></br>
Whether, we have a legitimate reason to keep it, like for safety or protection.
<br></br>
<h3>Your Rights</h3>

You have the following rights regarding your personal information:
<br></br>
Access: You have the right to access the personal information we hold about you and request copies of your data.
<br></br>
Correction: You can request corrections to any inaccuracies in your personal information.
<br></br>
Deletion: You have the right to request the deletion of your personal information, subject to certain exceptions as per applicable laws.
 <h3>Changes to this Policy</h3>
We reserve the right to modify or update this Privacy Policy periodically. Any changes will be communicated to you through appropriate channels, and the updated policy will be made available on our website.
<h3>Contact Us</h3>
If you have any questions, concerns, or requests regarding this Privacy Policy or our handling of your personal information, please contact us through:
<br></br>
Email us: rapidconnect-support@connectgenus.com 
<br></br>
Call us : +1 (866)-983-0491.
<br></br>
By utilizing our contact centre launch product, you consent to the collection, use, and disclosure of your information as outlined in this Privacy Policy.
      </div>
      );
};

export default Privacy;