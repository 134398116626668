import * as React from "react";
import { Grid } from "@mui/material";
import Input from "../../utilities/input";
import Label from "../../utilities/label";
import Button from "../../utilities/button";
import { queueDetailsContext } from "../../context";
 
export default function FormQueues({ handleClose, additionalQueues }) {
  let queuesForm = React.useContext(queueDetailsContext);
  const [queueDetails, setQueueDetails] = React.useState({
    queueName: "",
    queueDescription: "",
    queueChecked: true,
  });
  const [errorMessage, setErrorMessage] = React.useState({});
  React.useEffect(() => {
    if (queueDetails.queueName) {
      queuesForm.queueName = queueDetails.queueName;
    }
    if (queueDetails.queueDescription) {
      queuesForm.queueDescription = queueDetails.queueDescription;
    }
    if (queueDetails.queueChecked) {
      queuesForm.queueChecked = queueDetails.queueChecked;
    }
  }, [queueDetails, queuesForm]);
 
  const QueueDetailsInputFields = React.useMemo(
    () => [
      {
        label: "Queue name",
        valueKey: "queueName",
        type: "text",
        required: true,
        id: "queueName",
        value: queueDetails.queueName,
        disabled: false,
        placeholder: "e.g Financial queue",
        errorMessage: errorMessage["queueName"],
      },
      {
        label: "Description",
        valueKey: "queueDescription",
        type: "textarea",
        required: true,
        id: "queueDescription",
        value: queueDetails.queueDescription,
        disabled: false,
        placeholder: "Enter message",
        errorMessage: errorMessage["queueDescription"],
      },
    ],
    [queueDetails, errorMessage]
  );
 
  const handleOnChange = (e) => {
    const { id, value } = e.target;
    setQueueDetails((prevDetails) => ({
      ...prevDetails,
      [id]: value,
    }));
  };
  const handleQueuesFormValidation = (formData) => {
    const errors = {};
    const { queueName, queueDescription } = formData;
    if (!queueName) {
      errors["queueName"] = "Please enter queue name";
    }
    if (!queueDescription) {
      errors["queueDescription"] = "Please enter description";
    }
    setErrorMessage(errors);
    return Object.keys(errors).length === 0;
  };
 
  const handleSubmit = () => {
    const isValid = handleQueuesFormValidation(queueDetails);
    const duplicateQueue = additionalQueues.some(
        (el) => el.queueName === queueDetails.queueName
    );
    if (!isValid) {
      return;
    }
    if (duplicateQueue) {
      setErrorMessage({queueName:"This Queue already Exists. Please enter a different queue name."});
        return;
    }
    additionalQueues.push(queueDetails);
    handleClose();
};
 
  return (
    <div className="queue-form-container">
      <Grid container spacing={1} className="queue-container">
        {QueueDetailsInputFields.map((item, index) => (
          <Grid item xs={10} key={index}>
            <Label title={item.label} />
            <Input
              type={item.type}
              disabled={item.disabled}
              required={item.required}
              id={item.valueKey}
              label={item.label}
              handleOnChange={handleOnChange}
              value={queueDetails[item.valueKey]}
              placeholder={item.placeholder}
              errorMessage={item.errorMessage}
            />
          </Grid>
        ))}
        <Grid item xs={10} className="queue-button">
          <Button label={"Submit"} handleClick={handleSubmit} />
        </Grid>
      </Grid>
    </div>
  );
}