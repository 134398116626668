import * as React from "react";
import { Grid, Typography } from "@mui/material";
import MainTheme from "../mainTemplate";
import Input from "../../utilities/input";
import Title from "../../utilities/title";
import Label from "../../utilities/label";
import Button from "../../utilities/button";
import pdfFile from "../../assets/Salesforce Guidelines.pdf";
import { salesForceDetailsContext, notFoundStatusContext } from "../../context";
import { passwordValidation } from "../../preDefinedFunctions/inputFieldValidations";
import "./connectYourCRM.css";

export default function SalesForce() {
  let salesForce = React.useContext(salesForceDetailsContext);
  let notFoundStatus = React.useContext(notFoundStatusContext);
  const [salesForceDetails, setSalesForceDetails] = React.useState({
    apiUserPassword: salesForce.apiUserPassword || "",
    consumerKey: salesForce.consumerKey || "",
    consumerSecretKey: salesForce.consumerSecretKey || "",
    apiUserAccessToken: salesForce.apiUserAccessToken || "",
    salesForceApiVersion: salesForce.salesForceApiVersion || "",
    salesForceDomainURL: salesForce.salesForceDomainURL || "",
  });
  const [passwordVisibility, setPasswordVisibility] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState({});

  React.useEffect(() => {
    notFoundStatus.status = false;
  }, []);

  const SalesForceDetailsInputFields = React.useMemo(
    () => [
      {
        label: "API user password",
        valueKey: "apiUserPassword",
        type: "password",
        required: true,
        id: "api-User-Password",
        disabled: false,
        visibility: passwordVisibility,
        placeholder: "Your password",
        errorMessage: errorMessage["password"],
      },
      {
        label: "Consumer key",
        valueKey: "consumerKey",
        type: "text",
        required: true,
        id: "consumer-key",
        disabled: false,
        placeholder: "Your consumer key",
        errorMessage: errorMessage["consumerKey"],
      },
      {
        label: "Consumer secret key",
        valueKey: "consumerSecretKey",
        type: "text",
        required: true,
        id: "consumer-secret-key",
        disabled: false,
        placeholder: "Your consumer secret key",
        errorMessage: errorMessage["consumerSecretKey"],
      },
      {
        label: "API user access token",
        valueKey: "apiUserAccessToken",
        type: "text",
        required: true,
        id: "api-User-Access-Token",
        disabled: false,
        placeholder: "Your API user access token",
        errorMessage: errorMessage["apiUserAccessToken"],
      },
      {
        label: "Salesforce API version",
        valueKey: "salesForceApiVersion",
        type: "text",
        required: true,
        id: "salesForce-API-Version",
        disabled: false,
        placeholder: "e.g 60.0",
        errorMessage: errorMessage["salesForceApiVersion"],
      },
      {
        label: "Salesforce domain URL",
        valueKey: "salesForceDomainURL",
        type: "text",
        required: true,
        id: "salesForce-Domain-URL",
        disabled: false,
        placeholder: "e.g https://mycompany.my.salesforce.com",
        errorMessage: errorMessage["salesForceDomainURL"],
      },
    ],
    [passwordVisibility, errorMessage]
  );

  const handlePasswordValidation = (id, value) => {
    if (id === "password") {
      if (value.length < 8 && !passwordValidation(value)) {
        setErrorMessage({ [id]: "Please Enter Valid Password" });
        setSalesForceDetails((prevDetails) => ({
          ...prevDetails,
          [id]: value,
        }));
      } else {
        setErrorMessage({});
        setSalesForceDetails((prevDetails) => ({
          ...prevDetails,
          [id]: value,
        }));
      }
    }
  };

  const handleOnChange = (e) => {
    const { id, value } = e.target;
    if (id === "password") {
      handlePasswordValidation(id, value);
    }
    setSalesForceDetails((prevDetails) => ({
      ...prevDetails,
      [id]: value,
    }));
  };

  const handleVisibilty = (e) => {
    e.preventDefault();
    const { id } = e.target;
    if (id === "apiUserPassword") {
      setPasswordVisibility(!passwordVisibility);
    }
  };

  const downloadPDF = () => {
    // console.log("Opening guide link...");
    const link = document.createElement("a");
    link.href = pdfFile;
    link.download = "Salesforce Guide.pdf";
    link.click();
  };

  const openGuideLink = () => {
    downloadPDF();
  };

  React.useEffect(() => {
    const scriptId = "8870c80a-280e-4be9-8764-4327f6d2e5ec";
    const script = document.createElement("script");
    script.src =
      "https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js";
    script.async = true;
    script.id = scriptId;
    document.head.appendChild(script);
    window["amazon_connect"] =
      window["amazon_connect"] ||
      function () {
        (window["amazon_connect"].ac = window["amazon_connect"].ac || []).push(
          arguments
        );
      };
    window["amazon_connect"]("styles", {
      openChat: { color: "#ffffff", backgroundColor: "#F89921" },
      closeChat: { color: "#ffffff", backgroundColor: "#F89921" },
    });
    window["amazon_connect"](
      "snippetId",
      "QVFJREFIakZhMVo2ZGZmSXpGSnpJS2lYakthYVBxMmJIU0ZPbnhET3AyalJDV1F3UWdIL280N3hDYkhlTXNzbFJ4M3VxcVNuQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNdlBkQjJWSUpuL1h1REd6cEFnRVFnQ3VzSE01bG5SOTdab29RSHVPM1YrVFlRRWVrRHowbnN0SnBBTTg2cXlBRlBjdVQ4aVBMcWk5TkczZWg6OmtiaUY0MHdNRzh0ZGl6cG1XbzBkRXNRRmxlb2NLWjF6VWpyVzFOdnBNMmpwTjVhaTZOQUw1ZmRuY1JuM296KytUMkVSc28xTVhzdEljNzJhOFlkeHdUSHdmREFmdktUVElkWWRGSHF3VFhIeWxlZ252RHFaSTFGZFgzdCtISjZnOVNxNEE4L2VUV1dVbElpVlJhN2VuMFV4T25WcjFJMD0="
    );
    window["amazon_connect"]("customLaunchBehavior", {
      skipIconButtonAndAutoLaunch: true,
      alwaysHideWidgetButton: true,
      programmaticLaunch: (launchCallback) => {
        const launchWidgetBtn = document.getElementById("launch-widget-btn");
        if (launchWidgetBtn) {
          launchWidgetBtn.addEventListener("click", () => {
            const container = document.createElement("div");
            container.id = "amazon-connect-container";
            document.body.appendChild(container);
            window["amazon_connect"]("containerId", "amazon-connect-container");
            launchCallback();
          });

          window.onunload = () => {
            launchWidgetBtn.removeEventListener("click", launchCallback);
            return;
          };
        }
      },
    });
    return () => {
      const scriptElement = document.getElementById(scriptId);
      if (scriptElement) {
        document.head.removeChild(scriptElement);
      }
    };
  }, []);

  React.useEffect(() => {
    if (salesForceDetails.apiUserPassword) {
      salesForce.apiUserPassword = salesForceDetails.apiUserPassword;
    }
    if (salesForceDetails.consumerKey) {
      salesForce.consumerKey = salesForceDetails.consumerKey;
    }
    if (salesForceDetails.consumerSecretKey) {
      salesForce.consumerSecretKey = salesForceDetails.consumerSecretKey;
    }
    if (salesForceDetails.apiUserAccessToken) {
      salesForce.apiUserAccessToken = salesForceDetails.apiUserAccessToken;
    }
    if (salesForceDetails.salesForceApiVersion) {
      salesForce.salesForceApiVersion = salesForceDetails.salesForceApiVersion;
    }
    if (salesForceDetails.salesForceDomainURL) {
      salesForce.salesForceDomainURL = salesForceDetails.salesForceDomainURL;
    }
  }, [salesForce, salesForceDetails]);

  const openContactUs = () => {
    // console.log("Contact us clicked!");
  };

  return (
    <MainTheme>
      <div className="head-container">
        <Title title={"Provide your Salesforce credentials"} />
      </div>
      <p className="paragraph">
        Please use the Guide for further clarification. If you continue to
        encounter difficulties, please utilize the Contact Us option to connect
        with a live agent.
      </p>
      <Grid container spacing={1} className="container-section">
        {SalesForceDetailsInputFields.map((item, index) => (
          <Grid
            item
            md={6}
            xs={12}
            spacing={1}
            className="input-div-class"
            key={index}
          >
            <Label title={item.label} />
            <Input
              type={item.type}
              disabled={item.disabled}
              required={item.required}
              id={item.valueKey}
              label={item.label}
              handleOnChange={handleOnChange}
              value={salesForceDetails[item.valueKey]}
              placeholder={item.placeholder}
              visibility={item.visibility}
              handleVisibilty={handleVisibilty}
              errorMessage={item.errorMessage}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={1} className="btn-class">
        <Grid item className="guide-button">
          <Button handleClick={openGuideLink} label={"Guide"} />
        </Grid>
        <Grid item className="contact-button">
          <Button
            label={"Contact Us"}
            id="launch-widget-btn"
            handleClick={openContactUs}
          />
        </Grid>
      </Grid>
    </MainTheme>
  );
}