import * as React from "react";
import { Box } from "@mui/material";
import "./App.css";
import Router from "./router";
import Footer from "./components/footer/index";

function Center({ children }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      {children}
    </Box>
  );
}

export default function App() {
  return (
    <div className="App">
      <Center>
        <Router />
      </Center>
      <Footer />
    </div>
  );
}