import * as React from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CCLandingPage from "../../assets/CC-Landing Page.svg";
import ContactCenterBuilderLogo from "../../assets/LandingPage-ccLogo.png";
import successKPIImage from "../../assets/SuccessKPI-Logo.png";
import connectGenImage from "../../assets/connectGenImage.png";
import Button from "../../utilities/button/index";
import { homePageContext } from "../../context";
import "./home.css";

export default function Home() {
  let homePageDetails = React.useContext(homePageContext);
  let navigate = useNavigate();
  
  const generateUniqueID = () => {
    let id = "";
    for (let i = 0; i < 12; i++) {
      const randomDigit = Math.floor(Math.random() * 10);
      id += randomDigit.toString();
    }
    return id;
  };

  const handleContinue = () => {
    const uId = generateUniqueID();
    homePageDetails.uId = uId
    navigate("organization-details");
  };
 
  
  return (
    <Grid container spacing={1} className="landing-page-main-container">
      <Grid container item xs={12} md={8} className="home-container">
        <Grid item md={6} xs={12} className="landing-image-container">
          <img
            className="landing-image"
            src={CCLandingPage}
            alt="Landing Page"
            loading="lazy"
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          container
          className="right-content-div"
          spacing={2}
        >
          <Grid item xs={12}>
            <div className="right-end-container">
              <div className="partner-logo-container">
                <div>
                  <img
                    className="partner-image"
                    src={successKPIImage}
                    alt="SuccessKPI Logo"
                    loading="lazy"
                  />
                </div>
                <div>
                  <img
                    className="partner-image"
                    src={connectGenImage}
                    alt="ConnectGen Logo"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="right-content">
              <div className="home-logo-container">
                <img
                  src={ContactCenterBuilderLogo}
                  alt="Contact Center Logo"
                  className="landing-logo"
                  loading="lazy"
                />
              </div>
              <div className="right-sub-container">
                <div>
                  <h1 className="title">Welcome to Contact Center Builder</h1>
                  <h5 className="sub-title">
                    Congratulations on your first step towards 21st century
                    Contact Center!
                  </h5>
                </div>
                <div className="continue-container">
                  <Button handleClick={handleContinue} label={"Continue"} />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}