import * as React from "react";
import "./companyDetails.css";
import TickIcon from "../../assets/Tick-Icon.svg";

export default function MessagePopUp() {
  return (
    <div className="messagePopUp">
      <h5>Thank you for your interest in creating a contact center with us. Your details have been submitted successfully.</h5>
      <div className="tickIcon">
      <img src={TickIcon} alt="TickIcon GIF" loading="lazy" />
      </div>
      <h5>Our technical team will reach out to you with an update in the next 72 business hours.</h5>
    </div>
  );
}
