import * as React from "react";
import "./footer.css";
import Button from "../../utilities/button";
import { Grid, Typography } from "@mui/material";
export default function Footer() {
  const handleCookieClick=()=>{
    window.open("/cookies");
  }
  const handleTermsClick=()=>{
    window.open("./terms");
  }
  const handlePrivacyClick=()=>{
    window.open("/privacy");
  }
  const handleClose=()=>{
    window.close(""/"");
  }
  const openContactUs = () => {
    // console.log("Contact us clicked!");
  };
 
 
  React.useEffect(() => {
    const scriptId = "8870c80a-280e-4be9-8764-4327f6d2e5ec";
    const script = document.createElement("script");
    script.src =
      "https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js";
    script.async = true;
    script.id = scriptId;
    document.head.appendChild(script);
    window["amazon_connect"] =
      window["amazon_connect"] ||
      function () {
        (window["amazon_connect"].ac = window["amazon_connect"].ac || []).push(
          arguments
        );
      };
    window["amazon_connect"]("styles", {
      openChat: { color: "#ffffff", backgroundColor: "#F89921" },
      closeChat: { color: "#ffffff", backgroundColor: "#F89921" },
    });
    window["amazon_connect"](
      "snippetId",
      "QVFJREFIakZhMVo2ZGZmSXpGSnpJS2lYakthYVBxMmJIU0ZPbnhET3AyalJDV1F3UWdIL280N3hDYkhlTXNzbFJ4M3VxcVNuQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNdlBkQjJWSUpuL1h1REd6cEFnRVFnQ3VzSE01bG5SOTdab29RSHVPM1YrVFlRRWVrRHowbnN0SnBBTTg2cXlBRlBjdVQ4aVBMcWk5TkczZWg6OmtiaUY0MHdNRzh0ZGl6cG1XbzBkRXNRRmxlb2NLWjF6VWpyVzFOdnBNMmpwTjVhaTZOQUw1ZmRuY1JuM296KytUMkVSc28xTVhzdEljNzJhOFlkeHdUSHdmREFmdktUVElkWWRGSHF3VFhIeWxlZ252RHFaSTFGZFgzdCtISjZnOVNxNEE4L2VUV1dVbElpVlJhN2VuMFV4T25WcjFJMD0="
    );
    window["amazon_connect"]("customLaunchBehavior", {
      skipIconButtonAndAutoLaunch: true,
      alwaysHideWidgetButton: true,
      programmaticLaunch: (launchCallback) => {
        const launchWidgetBtn = document.getElementById("launch-widget-btn");
        if (launchWidgetBtn) {
          launchWidgetBtn.addEventListener("click", () => {
            const container = document.createElement("div");
            container.id = "amazon-connect-container";
            document.body.appendChild(container);
            window["amazon_connect"]("containerId", "amazon-connect-container");
            launchCallback();
          });
 
          window.onunload = () => {
            launchWidgetBtn.removeEventListener("click", launchCallback);
            return;
          };
        }
      },
    });
    return () => {
      const scriptElement = document.getElementById(scriptId);
      if (scriptElement) {
        document.head.removeChild(scriptElement);
      }
    };
  }, []);
 
  return (
    <footer className="footer">
      <p className="footer-copyright">
        &copy; Copyright 2024 - 2025 Contact Center Builder
      </p>
     
      <div className="footer-content">
        <ul className="footer-menu">
          <li>
           <Button className="button-link"
            label={"Contact Us"}
            id="launch-widget-btn"
            handleClick={openContactUs}
            />
          </li>
         
          {/* <li>
            <a
              href="https://www.connectgenus.com/team_category/specialists/"
              className="footer-link"
            >
              Help Desk
            </a>
          </li> */}
          {/* <li>
          <a onClick={handlePrivacyClick} className="footer-link">
              Privacy and Policy
            </a>
          </li>
          <li>
          <a onClick={handleTermsClick} className="footer-link">
              Terms and Conditions
            </a>
          </li>
          <li>
          <a onClick={handleCookieClick} className="footer-link">
            Cookie Policy
          </a>
        </li> */}
        </ul>
      </div>
    </footer>
  );
}