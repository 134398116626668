import * as React from "react";
import "./label.css";

export default function Label({title}){

    return(
        <div>
            <p className="label-class"> {title} </p>
        </div>
    );
}