export const bussinessHoursList = [
  "24x7",
  "24x5",
  "12x7",
  "12x5",
  "9x7",
  "9x5",
  "Custom hours",
];

export const getDaysofBussinessHours = {
  "": [],
  "24x7": [
    [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    ["00:00", "23:59"],
  ],
  "24x5": [
    ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    ["00:00", "23:59"],
  ],
  "12x7": [
    [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    ["08:00", "20:00"],
  ],
  "12x5": [
    ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    ["08:00", "20:00"],
  ],
  "9x7": [
    [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    ["09:30", "18:30"],
  ],
  "9x5": [
    ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    ["09:30", "18:30"],
  ],
};

export const callHandlingOptions = ["Voicemail", "Custom prompt message"];
export const callRecordingOptions=[90,120,180,365,"No Expiry"]