import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Title from "../title";
import "./modal.css";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -40%)",
  // width: "50%",
  bgcolor: "background.paper",
  border: "1px solid #0626398f",
  boxShadow: 24,
  p: 2,
  borderRadius: "1px",
};

export default function ModalForm({
  open,
  handleClose,
  component,
  showCloseIcon,
  title,
  className
}) {
  const handlePreventClose = (event) => {
    event.stopPropagation();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handlePreventClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-container">
          <div className="form-modal">
            {(showCloseIcon || title) && (
              <div className="close-icon-div">
                <div>
                  <Title title={title} />
                </div>
                {(showCloseIcon && 
                  <CloseIcon onClick={handleClose} className="close-icon" />
                )}
              </div>
            )}
            <div className={className?className:"form-modal-component"}>{component}</div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
