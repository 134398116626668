import { axiosInstance } from "./axiosInstance";
import { endpoints } from "./endpoints";

export const ErrorServiceLogger = (
  errorMessage,
  accessUrl,
  payloadBody,
  path
) => {
  const payload = {
    access_url: accessUrl,
    error_message: errorMessage,
    payload: payloadBody,
    resource_path: path,
  };
  axiosInstance
    .post(endpoints.errorLoggers, payload)
    .then((response) => {
      if (response) {
        // console.log(response);
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
