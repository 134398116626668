import * as React from "react";
import { Grid } from "@mui/material";
import "./title.css";

export default function Title({title}){

    return(
        <Grid>
            <h3 className="title-class"> {title} </h3>
        </Grid>
    );
}