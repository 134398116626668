import * as React from "react";
import { Grid, Button } from "@mui/material";
import MainTheme from "../mainTemplate";
import Title from "../../utilities/title";
import "./connectYourCRM.css";
import salesforceIcon from "../../assets/salesforce.svg";
import zendeskIcon from "../../assets/zendesk.svg";
import serviceNowIcon from "../../assets/servicenow.svg";
import ModalForm from "../../utilities/modal";
import GuidePopUp from "./guidePopUp";
import { crmToolTypeContext, notFoundStatusContext } from "../../context";
import { useNavigate } from "react-router-dom";

export default function ConnectYourCRM() {
  let navigate = useNavigate();
  const [openGuidePopUp, setOpenGuidePopUp] = React.useState(false);
  const [salesforceVisible, setSalesforceVisible] = React.useState(false);
  let crmType = React.useContext(crmToolTypeContext);
  let notFoundStatus = React.useContext(notFoundStatusContext);

  React.useEffect(() => {
    if (salesforceVisible) {
      crmType.selectCRMType.salesForce = salesforceVisible;
    }
    notFoundStatus.status = false;
  }, []);

  React.useEffect(() => {
    if (salesforceVisible) {
      crmType.selectCRMType.salesForce = salesforceVisible;
    }
  }, [crmType.selectCRMType, salesforceVisible]);

  const handleOpenGuidePopUp = () => {
    setOpenGuidePopUp(true);
  };

  const handleCloseGuidePopUp = () => {
    setOpenGuidePopUp(false);
    setSalesforceVisible(true);
    navigate("/sales-force-form");
  };
  React.useEffect(() => {
    if (openGuidePopUp) {
      const timer = setTimeout(() => {
        handleCloseGuidePopUp();
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [openGuidePopUp]);

  const CRMToolTypeInputFields = [
    {
      label: "Sales Force",
      valueKey: "salesForce",
      variant: "outlined",
      disabled: false,
      icon: salesforceIcon,
      height: "85px",
      width: "120px",
    },
    {
      label: "Zendesk",
      valueKey: "zendesk",
      variant: "contained",
      disabled: true,
      icon: zendeskIcon,
      height: "60px",
      width: "120px",
    },
    {
      label: "ServiceNow",
      valueKey: "seviceNow",
      variant: "contained",
      disabled: true,
      icon: serviceNowIcon,
      height: "60px",
      width: "120px",
    },
  ];
  return (
    <MainTheme>
      <Title title={"Select your CRM tool (Optional)"} />
      <Grid
        container
        spacing={2}
        justifyContent="center"
        className="crm-container"
        item
        md={12}
        xs={12}
      >
        {CRMToolTypeInputFields.map((tool, index) => (
          <Grid item md={4} xs={7} className="crm-tools-grid">
            <Button
              onClick={handleOpenGuidePopUp}
              variant={tool.variant}
              id={tool.valueKey}
              disabled={tool.disabled}
              className="crm-button-div"
            >
              <div>
                <img
                  src={tool.icon}
                  alt={tool.label}
                  height={tool.height}
                  width={tool.width}
                  loading="lazy"
                />
                {index > 0 && (
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "5px",
                      textTransform: "none",
                    }}
                  >
                    Coming Soon...
                  </p>
                )}
              </div>
            </Button>
          </Grid>
        ))}
      </Grid>
      {openGuidePopUp && (
        <ModalForm
          className="short-modal"
          open={openGuidePopUp}
          handleClose={handleCloseGuidePopUp}
          component={<GuidePopUp />}
        />
      )}
    </MainTheme>
  );
}
