import * as React from "react";
import { Grid } from "@mui/material";
import MainTheme from "../mainTemplate";
import Input from "../../utilities/input";
import Title from "../../utilities/title";
import Label from "../../utilities/label";
import ModalForm from "../../utilities/modal";
import QueuesForm from "./queuesForm";
import IntentForm from "./intentForm";
import IntentEditForm from "./intentEditForm";
import CancelIcon from "@mui/icons-material/Cancel";
import { addIntentsContext } from "../../context";
import { axiosInstance } from "../../services/axiosInstance";
import { endpoints } from "../../services/endpoints";

import {
  hoursDetailsContext,
  additionalQueueDetailsContext,
  hoursOfOperationsPayloadContext,
  contactFlowContext,
  companyDetailsContext,
  notFoundStatusContext,
  homePageContext,
} from "../../context";
import {
  bussinessHoursList,
  getDaysofBussinessHours,
  callHandlingOptions,
  callRecordingOptions,
} from "./bussinessHoursList";
import { generateConfigDaysPayload } from "../../preDefinedFunctions/getdaysConfig";
import CustomHours from "./customHours";
import "./selectQueues.css";
import { ConnectingAirportsOutlined } from "@mui/icons-material";

export default function HoursOperation() {
  const companyDetails = React.useContext(companyDetailsContext);
  const homePageDetails = React.useContext(homePageContext);
  let addIntentsData = React.useContext(addIntentsContext);
  let additionalQueueDetails = React.useContext(additionalQueueDetailsContext);
  let hoursOfOperationsPayload = React.useContext(
    hoursOfOperationsPayloadContext
  );
  let contactFlowDetails = React.useContext(contactFlowContext);
  let notFoundStatus = React.useContext(notFoundStatusContext);
  let hoursDetails = React.useContext(hoursDetailsContext);
  const [hoursOperation, setHoursOperation] = React.useState({
    timeZone: "",
    businessHours: "",
    callHandling: "",
    callRecording: "",
    description: "",
    greetingMessage: "",
    description: '',
  });

  const [openQueuesForm, setOpenQueuesForm] = React.useState(false);
  const [openIntentForm, setOpenIntentForm] = React.useState(false);
  const [intentList, setIntentList] = React.useState([]);
  // const[callRecording,setCallRecording]=React.useState('')
  const [editIntentForm, setEditIntentForm] = React.useState(false);
  const [editIntent, setEditIntent] = React.useState({});
  const [editIndex, setEditIndex] = React.useState(null);
  const [timeZoneList, setTimeZoneList] = React.useState([]);
  const [timeZoneType, setTimeZoneType] = React.useState(hoursDetails.timeZone);
  const [businessHoursType, setBusinessHoursType] = React.useState(
    hoursDetails.businessHours
  );
  const [callHandlingType, setCallHandlingType] = React.useState(
    hoursDetails.callHandling
  );
  const [callRecordingType, setRecordingType] = React.useState(
    hoursDetails.callRecording
  );
  const [selectQueuesType, setSelectQueuesType] = React.useState(
    hoursDetails.selectQueues
  );

  const [selectQueues, setSelectQueues] = React.useState({
    salesQueue: false,
    technicalQueue: false,
    generalQueue: true,
    vIPQueue: false,
  });
  const [initialQueues, setInitialQueues] = React.useState({
    salesQueue: false,
    technicalQueue: false,
    generalQueue: true,
    vIPQueue: false,
  });
  const [additionalQueues, setAdditionalQueues] = React.useState(
    additionalQueueDetails
  );
  const [errorMessage, setErrorMessage] = React.useState({});
  const [customPromptField, setCustomPromptField] = React.useState(false);
  const [customHoursPopup, setOpenCustomHoursPopup] = React.useState(false);
  const [customHoursList, setCustomHoursList] = React.useState([]);
  const [hoursConfigList, setHoursConfigList] = React.useState([]);
  const [contactCenterType, setContactCenterType] = React.useState(
    companyDetails.selectCompanyType
  );

  React.useEffect(() => {
    // Initialize additional queues based on contactCenterType
    const additionalQueues = [];
  
    if (contactCenterType.sales) {
      additionalQueues.push({
        queueName: "Sales Queue",
        queueDescription: "Sales Queue Description",
      });
    }
  
    if (contactCenterType.techSupport) {
      additionalQueues.push({
        queueName: "Technical Queue",
        queueDescription: "Technical Queue Description",
      });
    }
  
    // Set additionalQueues with unique entries
    setAdditionalQueues(additionalQueues);
  
    // Initialize selectQueues and initialQueues
    const initialState = {
      salesQueue: contactCenterType.sales === true,
      technicalQueue: contactCenterType.techSupport === true,
      generalQueue: true,
      vIPQueue: false,
    };
  
    setSelectQueues(initialState);
    setInitialQueues(initialState);
  
  }, [contactCenterType.sales, contactCenterType.techSupport]);
  

  React.useEffect(() => {
    if (intentList) {
      addIntentsData.addIntents = intentList;
    }
  }, [addIntentsData, intentList]);

  React.useEffect(() => {
    getCountryTimeZoneList();
    notFoundStatus.status = false;
  }, []);

  React.useEffect(() => {
    if (additionalQueues) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      additionalQueueDetails = additionalQueues;
    }
  }, [additionalQueues]);

  React.useEffect(() => {
    if (hoursOperation.timeZone) {
      hoursDetails.timeZone = hoursOperation.timeZone;
    }
    if (hoursOperation.businessHours) {
      hoursDetails.businessHours = hoursOperation.businessHours;
    }
    if (hoursOperation.callHandling) {
      hoursDetails.callHandling = hoursOperation.callHandling;
    }
    if (hoursOperation.callRecording) {
      hoursDetails.callRecording = hoursOperation.callRecording;
    }
    if (hoursOperation.greetingMessage) {
      hoursDetails.greetingMessage = hoursOperation.greetingMessage;
    }
    if (hoursOperation.description) {
      hoursDetails.description = hoursOperation.description;
    }
    if (selectQueuesType) {
      hoursDetails.selectQueues = selectQueuesType;
    }
  }, [
    hoursOperation.businessHours,
    hoursOperation.timeZone,
    hoursOperation.callHandling,
    hoursOperation.greetingMessage,
    hoursOperation.callRecording,
    hoursOperation.description,
    selectQueuesType,
    hoursOperation,
  ]);

  React.useEffect(() => {
    let payload = {};
    let payload_customPrompt = {
      accessUrl: companyDetails.companyAccessUrl,
      // PromptMessage: hoursOperation.description,
      GreetingMessage: hoursOperation.greetingMessage,
      uniqueId: homePageDetails.uId,
      regionName: companyDetails.awsAccountDetails.region,
      companyName: companyDetails.companyName,
      awsAccountNumber: companyDetails.accountNumber,
      roleArn: companyDetails.awsAccountDetails.roleArn,

    };

    if (hoursOperation.callHandling === "Voicemail") {
      payload = {
        accessUrl: companyDetails.companyAccessUrl,
        uniqueId: homePageDetails.uId,
        companyName: companyDetails.companyName,
        GreetingMessage : hoursOperation.greetingMessage,
      };

      contactFlowDetails.voiceMailPayload = payload;
      contactFlowDetails.customPromptPayload = payload_customPrompt;
    }
      if (hoursOperation.greetingMessage ) {
        payload.GreetingMessage = hoursOperation.greetingMessage;
    }

    if (
      hoursOperation.callHandling === "Custom prompt message" &&
      hoursOperation.description
    ) {
      payload = {
        accessUrl: companyDetails.companyAccessUrl,
        PromptMessage: hoursOperation.description,
        GreetingMessage: hoursOperation.greetingMessage,
        uniqueId: homePageDetails.uId,
        regionName: companyDetails.awsAccountDetails.region,
        companyName: companyDetails.companyName,
        awsAccountNumber: companyDetails.accountNumber,
        roleArn: companyDetails.awsAccountDetails.roleArn,
      };
      contactFlowDetails.customPromptPayload = payload;
    }
   

     if (hoursOperation.greetingMessage) {
      let promtMessage = ""
      if (
        hoursOperation.callHandling === "CustomPrompt" &&
  hoursOperation.description
      ){
        promtMessage = hoursOperation.description
      }
      payload = {
        accessUrl: companyDetails.companyAccessUrl,
       GreetingMessage: hoursOperation.greetingMessage,

        uniqueId : homePageDetails.uId,
        regionName:companyDetails.awsAccountDetails.region,
      // promtMessage : hoursOperation.description,
     companyName: companyDetails.companyName,
         awsAccountNumber: companyDetails.accountNumber,
         roleArn: companyDetails.awsAccountDetails.roleArn,
      };
       contactFlowDetails.greetingMessagePayload = payload;
   }
  }, [companyDetails.companyAccessUrl, contactFlowDetails, hoursOperation]);

  //    if (hoursOperation.greetingMessage) {
  //      let promtMessage = "";
  //   if (
  //      hoursOperation.callHandling === "Custom prompt message" &&
  //       hoursOperation.description
  //   ) {
  //       promtMessage = hoursOperation.description;
  //    }
  //    payload = {
        
  //       accessUrl: companyDetails.companyAccessUrl,
  //       GreetingMessage: hoursOperation.greetingMessage,
  //       PromptMessage: promtMessage,
  //      uniqueId: homePageDetails.uId,
  //       regionName: companyDetails.awsAccountDetails.region,
  //      companyName: companyDetails.companyName,
  //        awsAccountNumber: companyDetails.accountNumber,
  //      roleArn: companyDetails.awsAccountDetails.roleArn,
  //  };
  //    contactFlowDetails.greetingMessagePayload = payload;
  //  }
//  }, [companyDetails.companyAccessUrl, contactFlowDetails, hoursOperation]);

  async function getCountryTimeZoneList() {
    await axiosInstance
      .get(endpoints.selectQueues.getCountryTimeZoneList)
      .then((response) => {
        if (response.status === 200) {
          setTimeZoneList(response.data.split(", "));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const hoursOperationInputFields = React.useMemo(
    () => [
      {
        label: "Timezone",
        type: "select",
        valueKey: "timeZone",
        required: true,
        id: "select-timezone",
        disabled: false,
        placeholder: "Select timezone",
        value: hoursOperation.timeZone,
        options: timeZoneList,
        errorMessage: errorMessage["timeZone"],
      },
      {
        label: "Business hours",
        type: "select",
        valueKey: "businessHours",
        required: true,
        id: "select-businesshours",
        disabled: false,
        placeholder: "Select business hours",
        value: hoursOperation.businessHours,
        options: bussinessHoursList,
        errorMessage: errorMessage["businessHours"],
      },
      {
        label: "Welcome greeting message",
        type: "text",
        disabled: false,
        required: true,
        id: "greetingMessage",
        valueKey: "greetingMessage",
        value: hoursOperation.greetingMessage,
        placeholder: "Enter greeting message",
        errorMessage: errorMessage["greetingMessage"],
      },
      {
        label:
          hoursOperation?.businessHours == "24x7"
            ? ""
            : "Call handling - after hours",
        type: hoursOperation?.businessHours == "24x7" ? "" : "select",
        valueKey: "callHandling",
        required: true,
        id: "select-businesshours",
        disabled: hoursOperation?.businessHours == "24x7" ? true : false,
        placeholder: "Select options",
        value: hoursOperation.callHandling,
        options: callHandlingOptions,
        errorMessage: errorMessage["callHandling"],
      },
      {
        label: "Call Recording - Retention period",
        type: "select",
        valueKey: "callRecording",
        required: true,
        id: "select-call-Recording",
        disabled: hoursOperation?.businessHours !== "24x7" && !hoursOperation.callHandling,
        placeholder: "Select option",

        options: callRecordingOptions,
        value: hoursOperation.callRecording,

        errorMessage: errorMessage["callRecording"],
      },
    ],
    [
      hoursOperation.businessHours,
      hoursOperation.timeZone,
      hoursOperation.callHandling,
      hoursOperation.greetingMessage,
      hoursOperation.callRecording,
      timeZoneList,
      errorMessage,
    ]
  );

  const customPromptHandlingField = React.useMemo(
    () => [
      {
        type: "text",
        disabled: false,
        required: true,
        id: "description",
        valueKey: "description",
        label: "Prompt Message",
        value: hoursOperation.description,
        placeholder: "Enter prompt message",
        errorMessage: errorMessage["description"],
      },
    ],
    [hoursOperation, errorMessage]
  );

  const selectQueuesInputFields = React.useMemo(
    () => [
      {
        label: "Sales Queue",
        valueKey: "salesQueue",
        type: "checkbox",
        value: selectQueues.salesQueue,
      },
      {
        label: "Technical Queue",
        valueKey: "technicalQueue",
        type: "checkbox",
        value: selectQueues.technicalQueue,
      },
      {
        label: "General Queue",
        valueKey: "generalQueue",
        type: "checkbox",
        value: selectQueues.generalQueue,
      },
      {
        label: "VIP Queue",
        valueKey: "vIPQueue",
        type: "checkbox",
        value: selectQueues.vIPQueue,
      },
    ],
    [selectQueues]
  );


  const handleValidateBussinessHours = (name, value) => {
    if (!hoursOperation.timeZone) {
      setErrorMessage({ timeZone: "Please select time zone" });
      setHoursOperation((prevDetails) => ({
        ...prevDetails,
        [name]: value,
        // callRecording: value === "Custom prompt message" ? null : prevDetails.callRecording,
      
      }));
      return;
    } else {
      setErrorMessage({});
      setHoursOperation((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleValidateCallHandling = (name, value) => {
    if (value === "Custom prompt message") {
      setCustomPromptField(true);
      setHoursOperation((prevDetails) => ({
        ...prevDetails,
        [name]: value,
         callRecording: null,
      }));
    
      setErrorMessage((prevError) => ({
        ...prevError,
        description: '',
      }));
    } else {
      setCustomPromptField(false);
      //this is giving the change 
      setHoursOperation((prevDetails) => ({
        ...prevDetails,
        [name]: value,
       callRecording:null,
        
      }));
      setErrorMessage((prevError) => ({
        ...prevError,
        value:'',
        description: '',
      }));
    }
  };
  
console.log('callRecordig;',hoursDetails.callRecording)
  const handleCustomHours = (name, value) => {
    setOpenCustomHoursPopup(true);
    if (!hoursOperation.timeZone) {
      setErrorMessage({ timeZone: "Please select time zone" });
      setHoursOperation((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
      return;
    } else {
      setErrorMessage({});
      setHoursOperation((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleCloseCustomHoursPopup = () => {
    setOpenCustomHoursPopup(false);
  };
  const handleValidatecallRecording =(name,value) =>{
    if(!hoursOperation.callRecording){
      // setErrorMessage({ callRecording: "Please select callRecording" });
      setHoursOperation((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
      return;
    } else {
      setErrorMessage({});
      setHoursOperation((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  }

  const handleOnChange = (e) => {
    // console.log("Hours", hoursOperation)
    const { id, value, name } = e.target;
    if (name === "businessHours" && value !== "Custom hours") {
      handleValidateBussinessHours(name, value);
      handleCustomHours(name, value);
    } else if (name === "callHandling") {
      handleValidateCallHandling(name, value);
    } else if (name === "businessHours" || value === "Custom hours") {
      setHoursConfigList([]);
      handleCustomHours(name, value);
    } else if(name ==="callRecording"){
      handleValidatecallRecording(name,value);
    }
    else if (id === "description") {
      if (value.length < 10) {
        setErrorMessage({
          description: "Please enter prompt message with atleast 10 characters",
        });
        setHoursOperation((prevDetails) => ({
          ...prevDetails,
          [name ? name : id]: value,
        }));
      } else {
        setErrorMessage({});
        setHoursOperation((prevDetails) => ({
          ...prevDetails,
          [name ? name : id]: value,
        }));
      }
    } else {
      setErrorMessage({});
      setHoursOperation((prevDetails) => ({
        ...prevDetails,
        [name ? name : id]: value,
      }));
    }
    if (e.target.id === "+ Add more queues") {
      setOpenQueuesForm(!openQueuesForm);
    }
    if (e.target.id === "+ Add intents") {
      setOpenIntentForm(!openIntentForm);
    }
    if (customPromptField && !hoursOperation.description) {
      setErrorMessage({ description: "Please enter custom-prompt" });
      setHoursOperation((prevDetails) => ({
        ...prevDetails,
     
       disabled: true
      }));
      return false;
    }else{
      setErrorMessage({ description: "" })
      
    }
   

    
  };

  const handleOpenCloseQueuesForm = () => {
    setOpenQueuesForm(!openQueuesForm);
  };
  const handleOpenCloseIntentForm = () => {
    setOpenIntentForm(!openIntentForm);
  };

  const handleRemoveValuefromAdditionalQueues = (array, valueToRemove) => {
    let resultArray = array.filter(function (obj) {
      return obj.queueName !== valueToRemove;
    });
    setAdditionalQueues(resultArray);
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;

    if (initialQueues[id] && !checked) {
      return;
    }

    const updateQueueState = (queueName, queueDescription) => {
      const queueCheck = additionalQueues.some(
        (el) => el.queueName === queueName
      );
      if (checked && !queueCheck && additionalQueues.length <= 4) {
        setAdditionalQueues((prevQueues) => [
          ...prevQueues,
          { queueName, queueDescription },
        ]);
        setSelectQueues((prevState ) => ({
          ...prevState,
          [id]: checked,
        }));
      } else if (!checked) {
        {/*setAdditionalQueues*/} setAdditionalQueues((prevQueues) =>
          prevQueues.filter((queue) => queue.queueName !== queueName)
        );
      }
    };

    
    switch (id) {
      case "salesQueue":
        updateQueueState("Sales Queue", "Sales Queue Description");
        break;
      case "technicalQueue":
        updateQueueState("Technical Queue", "Technical Queue Description");
        break;
      case "vIPQueue":
        if (checked && additionalQueues.length >= 4) {
          setSelectQueues((prevValues) => ({
            ...prevValues,
            [id]: false,
          }));
          return;
        }
        updateQueueState("VIP Queue", "VIP Queue Description");
        break;
      default:
        if (!checked) {
          setAdditionalQueues((prevQueues) =>
            prevQueues.filter((queue) => queue.queueName !== id)

          );
        } else {
              const updatedQueues = additionalQueues.map((queue) =>
                queue.queueName === id ? { ...queue, queueChecked: true } : queue
          );
          setAdditionalQueues(updatedQueues);
        }
    }

    setSelectQueues((prevValues) => ({
      ...prevValues,
      [id]: checked,
    }));
  };

  // const handleCheckboxChange = (e) => {
  // 	const { id, checked } = e.target;
  // 	if (initialQueues[id] && !checked) {
  // 		return;
  // 	}

  // 	if (id === "salesQueue") {
  // 		const salesQueueCheck = additionalQueues.some(
  // 			(el) => el.queueName === "Sales Queue"
  // 		);
  // 		if (!salesQueueCheck && checked && additionalQueues.length < 6) {
  // 			additionalQueues.push({
  // 				queueName: "Sales Queue",
  // 				queueDescription: "Sales Queue Description",
  // 			});
  // 		} else {
  // 			handleRemoveValuefromAdditionalQueues(additionalQueues, "Sales Queue");
  // 		}
  // 		setSelectQueues((prevValues) => ({
  // 			...prevValues,
  // 			[id]: checked,
  // 		}));
  // 	}
  // 	if (id === "technicalQueue") {
  // 		const technicalQueueCheck = additionalQueues.some(
  // 			(el) => el.queueName === "Technical Queue"
  // 		);
  // 		if (!technicalQueueCheck && checked && additionalQueues.length < 6) {
  // 			additionalQueues.push({
  // 				queueName: "Technical Queue",
  // 				queueDescription: "Technical Queue Description",
  // 			});
  // 		} else {
  // 			handleRemoveValuefromAdditionalQueues(
  // 				additionalQueues,
  // 				"Technical Queue"
  // 			);
  // 		}
  // 		setSelectQueues((prevValues) => ({
  // 			...prevValues,
  // 			[id]: checked,
  // 		}));
  // 	}
  // 	if (id === "vIPQueue") {
  // 		if (additionalQueues.length < 6) {
  // 			setSelectQueues((prevValues) => ({
  // 				...prevValues,
  // 				[id]: checked,
  // 			}));
  // 		} else {
  // 			setSelectQueues((prevValues) => ({
  // 				...prevValues,
  // 				[id]: false,
  // 			}));
  // 		}

  // 		const vipQueueCheck = additionalQueues.some(
  // 			(el) => el.queueName === "VIP Queue"
  // 		);
  // 		if (!vipQueueCheck && checked && additionalQueues.length < 6) {
  // 			additionalQueues.push({
  // 				queueName: "VIP Queue",
  // 				queueDescription: "VIP Queue Description",
  // 			});
  // 		} else {
  // 			handleRemoveValuefromAdditionalQueues(additionalQueues, "VIP Queue");
  // 		}
  // 	} else {
  // 		setSelectQueues((prevValues) => ({
  // 			...prevValues,
  // 			[id]: checked,
  // 		}));
  // 		if (!checked) {
  // 			const filteredQueues = additionalQueues.filter(
  // 				(queue) => queue.queueName !== e.target.id
  // 			);
  // 			setAdditionalQueues(filteredQueues);
  // 		} else {
  // 			const updatedQueues = additionalQueues.map((queue) =>
  // 				queue.queueName === e.target.id
  // 					? { ...queue, queueChecked: e.target.checked }
  // 					: queue
  // 			);
  // 			setAdditionalQueues(updatedQueues);
  // 		}
  // 	}

  // 	console.log("AQ", additionalQueues);
  // };

  const handleCloseEditIntentForm = () => {
    setEditIntent({});
    setEditIndex(null);
    setEditIntentForm(false);
  };

  const handleremoveIntentFromList = (index) => {
    const filterIntentList = intentList.filter((item, i) => i !== index);
    setIntentList(filterIntentList);
  };

  React.useEffect(() => {
    handleCreateHoursOfOperationsQueue();
  }, [hoursOperation, additionalQueues, selectQueues, customHoursList]);

  const handleCreateHoursOfOperationsQueue = () => {
    let configList = [];
    
    if (
      hoursOperation.businessHours !== "Custom hours" &&
      hoursOperation.businessHours.length > 0
    ) {
      configList = generateConfigDaysPayload(
        getDaysofBussinessHours[hoursOperation?.businessHours]
      );
      setHoursConfigList(configList);
      configList = [...customHoursList];
    } else if (hoursOperation.businessHours === "Custom hours") {
      configList = [...customHoursList];
    } else {
      // console.log("please select timezone and hours");
      return;
    }

    const payload = {
      RandomHoursOfOperation: {
        Properties: {
          TimeZone: hoursOperation?.timeZone,
          Config:
            hoursOperation.businessHours === "Custom hours"
              ? customHoursList
              : configList,
        },
      },
      accessUrl: companyDetails.companyAccessUrl,
      //
      additionalQueue: additionalQueues ? additionalQueues : [],
    };
    // if (selectQueues.salesQueue) {
    //   payload.additionalQueue.push({
    //     queueName: "Sales Queue",
    //     queueDescription: "Sales Queue Description",
    //   });
    // }
    // if (selectQueues.technicalQueue) {
    //   .additionalQueue.push({
    //     queueName: "Technical Queue",
    //     queueDescription: "Technical Queue Description",
    //   });}
    hoursOfOperationsPayload.payload = payload;
  };
  return (
    <MainTheme>
      <Title title={"Call handling options"} />
      <div className="call-handling-section">
        <Grid container spacing={1} className="container-section">
          {hoursOperationInputFields.map((item, index) => (
            <Grid item md={6} xs={12} key={index} className="input-div-class">
              <Label title={item.label} />
              <Input
                type={item.type}
                disabled={item.disabled}
                required={item.required}
                id={item.valueKey}
                label={item.label}
                handleOnChange={handleOnChange}
                value={item.value}
                placeholder={item.placeholder}
                options={item.options}
                errorMessage={item.errorMessage}
              />
            </Grid>
          ))}
          {customPromptField &&
            customPromptHandlingField.map((item, index) => (
              <Grid item md={6} xs={12} key={index} className="input-div-class">
                <Label title="Custom prompt message" />
                <Input
                  type={item.type}
                  disabled={item.disabled}
                  required={item.required}
                  id={item.id}
                  label={item.label}
                  valueKey={item.description}
                  handleOnChange={handleOnChange}
                  value={item.value}
                  placeholder={item.placeholder}
                  errorMessage={item.errorMessage}
                />
              </Grid>
            ))}
        </Grid>
        <Label title={"Available queues"} />
        <Grid container spacing={1}>
          {selectQueuesInputFields.map((item, index) => (
            <Grid item md={2.5} xs={6} key={index} className="input-class">
              <Input
                id={item.valueKey}
                type="checkbox"
                label={item.label}
                value={item.value}
                checked={item.value}
                handleOnChange={handleCheckboxChange}
              />
            </Grid>
          ))}
        </Grid>
        {additionalQueues && (
          <Grid container spacing={1} className="additional-queue-container">
            {additionalQueues
              .filter(
                (item) =>
                  item.queueName !== "VIP Queue" &&
                  item.queueName !== "Sales Queue" &&
                  item.queueName !== "Technical Queue" &&
                  item.queueName !== "General Queue"
              )
              .map((item, index) => (
                <Grid item md={2.5} xs={6} key={index}>
                  <Input
                    id={item.queueName}
                    type="checkbox"
                    label={item.queueName}
                    value={item.queueChecked}
                    checked={item.queueChecked}
                    handleOnChange={handleCheckboxChange}
                  />
                </Grid>
              ))}
          </Grid>
        )}
        {openQueuesForm && (
          <ModalForm
            title={"Queue details"}
            open={openQueuesForm}
            showCloseIcon={true}
            handleClose={handleOpenCloseQueuesForm}
            className="short-modal"
            component={
              <QueuesForm
                handleClose={handleOpenCloseQueuesForm}
                additionalQueues={additionalQueues}
              />
            }
          />
        )}
        <Grid item className="add-more-queue-div">
          {additionalQueues.length !== 4 && (
            <p className="link-hover">
              <a onClick={handleOpenCloseQueuesForm}>+ Add more queues</a>
            </p>
          )}
        </Grid>
        {companyDetails.contactOptions.allowChat === true && (
          <>
            <Label title={"Configure intent / menu options"} />
            {openIntentForm && (
              <ModalForm
                title={"Intent(Goal you want to achieve)"}
                open={openIntentForm}
                showCloseIcon={true}
                className="short-modal"
                handleClose={handleOpenCloseIntentForm}
                component={
                  <IntentForm
                    handleClose={handleOpenCloseIntentForm}
                    intentList={intentList}
                  />
                }
              />
            )}
          </>
        )}
        {intentList.map((item, index) => (
          <div key={index} className="intent-item">
            <span
              className="intent-name"
              // onClick={() => handleEditIntentForm(item, index)}
            >
              {item.intentName}
              <CancelIcon
                className="cancel-icon"
                fontSize="small"
                onClick={() => handleremoveIntentFromList(index)}
              />
            </span>
          </div>
        ))}
        <Grid item className="add-intents-div">
          {companyDetails.contactOptions.allowChat === true &&
            intentList.length !== 3 && (
              <p className="link-hovering">
                <a onClick={handleOpenCloseIntentForm}>
      {intentList.length < 1 ? "+ Add intents" : "+ Add more intents"}
    </a>
              </p>
            )}
        </Grid>
        {/* {editIntentForm && (
          <ModalForm
            title={"Intent(Goal you want to achieve)"}
            open={editIntentForm}
            showCloseIcon={true}
            handleClose={handleCloseEditIntentForm}
            component={
              <IntentEditForm
                handleClose={handleCloseEditIntentForm}
                intent={editIntent}
                intentList={intentList}
                setIntentList={setIntentList}
                editIndex={editIndex}
              />
            }
          />
        )} */}
        {customHoursPopup && (
          <ModalForm
            title={
              hoursOperation.businessHours === "Custom hours"
                ? "Custom hours"
                : "Business hours" +
                  (hoursOperation.businessHours
                    ? ` - ${hoursOperation.businessHours}`
                    : "")
            }
            open={customHoursPopup}
            showCloseIcon={
              hoursOperation.businessHours === "Custom hours" ? true : false
            }
            handleClose={handleCloseCustomHoursPopup}
            component={
              <CustomHours
                handleClose={handleCloseCustomHoursPopup}
                setCustomHours={setCustomHoursList}
                hoursConfigList={hoursConfigList}
              />
            }
          />
        )}
      </div>
    </MainTheme>
  );
}
