import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MainTheme from "../mainTemplate";
import "./notFound.css";
import bubbleGumBoy from "../../assets/bubble-gum-boy-in-glasses.gif";
import Button from "../../utilities/button";
import { notFoundStatusContext } from "../../context";

export default function NotFound() {
  const navigate = useNavigate();
  let notFoundStatus = React.useContext(notFoundStatusContext);

  React.useEffect(() => {
    notFoundStatus.status = true;
  }, []);

  const handleNavigateToHome = () => {
    navigate("/");
  };

  return (
    <MainTheme>
      <Grid container spacing={2} className="not-found-page-container">
        <Grid item xs={6} className="not-found-text-area">
          <Typography className="not-found-404-text">404</Typography>
          <Typography className="not-found-sorry-text">
            <span className="not-found-oops-text">Oops!</span> This is
            awkward... you're looking for something that doesn't actually exist
          </Typography>
          <Button label={"Go To Homepage"} handleClick={handleNavigateToHome} />
        </Grid>
        <Grid item xs={6}>
          <img
            src={bubbleGumBoy}
            alt="not found gif"
            className="not-found-page-gif"
            loading="lazy"
          />
        </Grid>
      </Grid>
    </MainTheme>
  );
}