import * as React from "react";
import {
  getIntialCompanyDetails,
  getIntialAdminDetails,
  getIntialOthersForm,
  getIntialAgentDetails,
  getIntialmannuallyDetails,
  getInitialSalesForceDetails,
  getIntialClaimPhoneNumberDetails,
  getInitialCRMToolType,
  getInitialIntentDetails,
  getInitialHoursDetails,
  getAdditionalQueueDetails,
  getAdditionalAlternateMails,
  getInitialQueueDetails,
  getHomePageDetails,
} from "./initialFormValues";

export const homePageContext = React.createContext(getHomePageDetails);
export const companyDetailsContext = React.createContext(
  getIntialCompanyDetails
);

export const adminDetailsContext = React.createContext(getIntialAdminDetails);

export const othersFormContext = React.createContext(getIntialOthersForm);

export const agentDetailsContext = React.createContext(getIntialAgentDetails);

export const mannuallyDetailsContext = React.createContext(
  getIntialmannuallyDetails
);

export const salesForceDetailsContext = React.createContext(
  getInitialSalesForceDetails
);

export const claimPhoneNumberContext = React.createContext(
  getIntialClaimPhoneNumberDetails
);

export const crmToolTypeContext = React.createContext(getInitialCRMToolType);

export const hoursDetailsContext = React.createContext(getInitialHoursDetails);

export const queueDetailsContext = React.createContext(getInitialQueueDetails);

export const intentDetailsContext = React.createContext(
  getInitialIntentDetails
);

export const additionalQueueDetailsContext = React.createContext(
  getAdditionalQueueDetails
);
export const additionalAlternateMails =React.createContext(
  getAdditionalAlternateMails
)

export const hoursOfOperationsPayloadContext = React.createContext({
  payload: {},
});
export const addIntentsContext = React.createContext({ addIntents: [] });

export const contactFlowContext = React.createContext({
  customPromptPayload: {},
  voiceMailPayload: {},
  greetingMessagePayload: {},
});

export const loaderContext = React.createContext({ loader: false });
export const bulkUserCsvContext = React.createContext({ status: false });
export const checkInstaceAvailabilityContext = React.createContext({
  status: false,
});

export const addAgentsContext = React.createContext({ status: false });
export const salesForceStatusContext = React.createContext({ status: false });
export const notFoundStatusContext = React.createContext({ status: false });
