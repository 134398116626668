import React from "react";
import { Stepper, Step, StepLabel, Typography, Grid } from "@mui/material";
import StepConnector from "@mui/material/StepConnector";
import { withStyles } from "@mui/styles";
import Logo from "../../assets/Stepper-logo.png";
import "./stepper.css";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#007bff",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#007bff",
    },
  },
  line: {
    borderColor: "#bdbdbd",
    lineHeight: "10px",
    borderTopWidth: "3px !important",
    borderLeftWidth: "3px !important",
    borderRadius: 1,
  },
})(StepConnector);

const CustomStepper = ({ steps, activeStep }) => {
  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia("(max-width: 900px)").matches
  );

  React.useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 900px)");

    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    setIsMobile(mediaQuery.matches);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, [isMobile]);

  return (
    <Grid container spacing={1} className="stepper-main-grid">
      <Grid item md={12} xs={3} className="stepper-container-logo">
        <img
          src={Logo}
          alt="Company Logo"
          className="stepper-logo"
          loading="lazy"
        />
      </Grid>
      <Grid item md={12} xs={9} className="stepper-label-grid">
        <Stepper
          activeStep={activeStep}
          orientation={isMobile ? "horizontal" : "vertical"}
          className="stepper-div"
          connector={<QontoConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel className="stepper-step-label">
                <Typography
                  className={
                    activeStep === index
                      ? "active-stepper-label"
                      : "stepper-label"
                  }
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default CustomStepper;