import * as React from "react";
import { Grid, Typography } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./input.css";
import CircularProgress from "@mui/material/CircularProgress";

export default function Input({
  key,
  id,
  type,
  handleOnChange,
  value,
  label,
  checked,
  required,
  disabled,
  placeholder,
  options,
  handleVisibilty,
  visibility,
  phrases,
  handleKeyDown,
  handleFocusOut,
  handleOnCountryCode,
  errorMessage,
  maxLength,
  minLength,
  handleFocusIn,
}) {
  return (
    <Grid>
      {type === "button" && (
        <button
          className={value === true ? "active-input-label" : "input-label"}
          value={value}
          required={required}
          disabled={disabled}
          onClick={handleOnChange}
          id={id}
          fullwidth
        >
          {label}
        </button>
      )}
      {type === "text" && (
        <input
          className="text-input"
          label={label}
          id={id}
          key={key}
          type={type}
          onChange={handleOnChange}
          value={value}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          onBlur={handleFocusOut}
          maxLength={maxLength}
          minLength={minLength}
        />
      )}
      {type === "textarea" && (
        <textarea
          className="textarea-input"
          label={label}
          id={id}
          key={key}
          type={type}
          onChange={handleOnChange}
          value={value}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          onBlur={handleFocusOut}
          maxLength={maxLength}
          minLength={minLength}
        />
      )}
      {type === "organization-text" && (
        <input
          className="organization-text-input"
          label={label}
          id={id}
          key={key}
          type={type}
          onChange={handleOnChange}
          value={value}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          onFocus={handleFocusIn}
          onBlur={handleFocusOut}
          maxLength={maxLength}
          minLength={minLength}
        />
      )}
      {type === "number" && (
        <div className="number-input-div">
          <select
            name={id}
            className="number-select-input"
            required={required}
            disabled={disabled}
            onChange={handleOnCountryCode}
          >
            <option value="" disabled selected>
              {"Country code"}
            </option>
            {options.length > 0 ? (
              options.map((item, index) => (
                <option
                  id={index}
                  value={item.value}
                  className="text-input-option"
                >
                  {item}
                </option>
              ))
            ) : (
              <CircularProgress sx={{ color: "#1a567a" }} />
            )}
          </select>
          <input
            className="number-input"
            label={label}
            id={id}
            key={key}
            type={"text"}
            onChange={handleOnChange}
            value={value}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            onBlur={handleFocusOut}
            maxLength={10}
          />
        </div>
      )}
      {type === "phoneNumber" && (
        <div className="number-input-div">
          <input
            className="phoneNumber-input"
            label={label}
            id={id}
            key={key}
            type={"text"}
            onChange={handleOnChange}
            value="+1"
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            onBlur={handleFocusOut}
            maxLength={2}
          />
          <input
            className="number-input"
            label={label}
            id={id}
            key={key}
            type={"text"}
            onChange={handleOnChange}
            value={value}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            onBlur={handleFocusOut}
            maxLength={10}
          />
        </div>
      )}
      {type === "special-text" && (
        <input
          className="special-text-input"
          label={label}
          id={id}
          key={key}
          type={type}
          maxLength={maxLength}
          onChange={handleOnChange}
          value={value}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          onBlur={handleFocusOut}
        />
      )}
      {type === "checkbox" && (
        <div className="checkbox-input">
          <input
            type={type}
            id={id}
            key={key}
            onChange={handleOnChange}
            checked={checked}
            value={value}
            required={required}
            disabled={disabled}
          />
          <p className="queue-label">{label}</p>
        </div>
      )}
      {type === "email-text" && (
        <input
          className="email-text-input"
          label={label}
          id={id}
          key={key}
          type={type}
          onChange={handleOnChange}
          value={value}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          onBlur={handleFocusOut}
          maxLength={maxLength}
          minLength={minLength}
        />
      )}
      {type === "select" && (
        <select
          name={id}
          className="select-input"
          required={required}
          disabled={disabled}
          onChange={handleOnChange}
          value={value}
        >
          <option value="" disabled selected>
            {placeholder}
          </option>
          {options.length > 0 ? (
            options.map((item, index) => (
              <option
                key={index}
                value={item.value ? item.value : item}
                className="text-input-option"
              >
                {item.label ? item.label : item}
              </option>
            ))
          ) : (
            <CircularProgress sx={{ color: "#1a567a" }} />
          )}
        </select>
      )}
      {visibility && type === "password" && (
        <div className="password-input">
          <input
            className="text-input"
            label={label}
            id={id}
            key={key}
            type={"text"}
            onChange={handleOnChange}
            value={value}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          <VisibilityIcon
            id={id}
            onClick={handleVisibilty}
            className="text-icon"
          />
        </div>
      )}
      {!visibility && type === "password" && (
        <div className="password-input">
          <input
            className="text-input"
            label={label}
            id={id}
            key={key}
            type={type}
            onChange={handleOnChange}
            value={value}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          <VisibilityOffIcon
            id={id}
            onClick={handleVisibilty}
            className="text-icon"
          />
        </div>
      )}
      {type === "multi-select" && (
        <div>
          <input
            id={id}
            className="text-input"
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
            value={value}
          />
          {phrases?.map((item, index) => (
            <p id={index} className="text-input-option">
              {item}
            </p>
          ))}
        </div>
      )}
      {errorMessage?.length > 0 ? (
        <Grid item xs={12} className="error-div">
          <Typography className="error-text">{errorMessage}</Typography>
        </Grid>
      ) : (
        <Grid item xs={12} className="error-div">
          <Typography className="empty-text">sample text</Typography>
        </Grid>
      )}
    </Grid>
  );
}
