export const removeLSpaceFromString = (string) => {
  let resultString = "";
  resultString = string.replace(/^\s+/g, "");
  return resultString;
};

export const removeRSpacefromString = (string) =>{
    let resultString = "";
  resultString = string.replace(/\s+$/g, "");
  return resultString;
}
