import React from "react";
import "./styles.css";
const Cookie = () => {
  return (
    <div class="content-container">
      <h1>Cookies Policy</h1>
      <h3>1. What are cookies and similar technologies?</h3>
        Cookies are small pieces of data stored on your device (computer,
        tablet, mobile phone) by your web browser while browsing websites.
        Similar technologies, such as pixels, tags, and scripts, are also used
        to collect and track information and to improve and analyse our
        services.
      <h3>2. Understanding Cookies</h3>
     Cookies serve various purposes, such as enhancing user experience,
      personalizing content, and providing analytics.
      <h3>3. How do we use cookies?</h3>
        We use cookies and similar technologies for various purposes, including:
        Authentication: To verify your identity and authenticate you as a user
        of the Platform. Preferences: To remember your preferences and settings,
        such as language preferences and display settings. Security: To enhance
        the security of the Platform and protect against unauthorized access.
        Analytics: To collect information about how you interact with the
        Platform, such as which pages you visit and how long you spend on each
        page, to analyze and improve our services. Performance: To optimize the
        performance of the Platform, including by ensuring that content is
        delivered efficiently and reliably.
      <h3>4. Browser Cookie Controls</h3>
        We provide users with controls to manage their browser cookies. While
        most web browsers accept cookies by default, users have the option to
        modify their browser settings to decline cookies. However, disabling
        cookies may affect certain features of our platform.
      <h3>5. Security Measures and Product Integrity</h3>
      Cookies are integral to maintaining the security and integrity of our
      platform. They help identify unauthorized access attempts, recover
      accounts, and prevent fraudulent activities such as spam, phishing, and
      malware infections. Specific cookies like "sb," "dbln," and "datr" aid in
      securely identifying browsers, protecting against fraud, and preventing
      cross-site request forgery attacks.
      <h3>6. Online Advertising</h3>
      Cookies are used for online advertising purposes to personalize content
      and display relevant advertisements based on users' interests and browsing
      behaviour. These cookies help optimize ad delivery, measure ad
      performance, and prevent ad fraud. Users have the option to opt out of
      personalized advertising through their browser settings or by using
      third-party tools.
      <h3>7. Types of cookies we use</h3>
      Session cookies These are temporary cookies that are erased when you close
      your web browser. We use session cookies to maintain your session and to
      enable certain features of the Platform. Persistent cookies These cookies
      remain on your device after you close your browser and are stored until
      they expire or until you delete them. We use persistent cookies to
      remember your preferences and settings. Third-party cookies We may also
      use third-party cookies and similar technologies provided by trusted
      partners for analytics, advertising, and other purposes. These third
      parties may collect information about your online activities over time and
      across different websites and services.
      <h3>8. Your choices regarding cookies</h3>
      You have the right to choose whether to accept or decline cookies. Most
      web browsers automatically accept cookies, but you can usually modify your
      browser settings to decline cookies if you prefer. However, please note
      that disabling cookies may impact your ability to use the Platform and
      some features may not function properly.
      <h3>9. Privacy Compliance</h3>
      We are committed to comply with data privacy regulations and ensuring
      transparency in our cookie practices. Our use of cookies aligns with
      applicable laws and regulations to safeguard user privacy and data
      protection. For more information about cookies reach out to us: @
      rapidconnect-support@connectgenus.com & +1 (866)-983-0491
      <h3>10.Contact us</h3>
      If you have any questions about this Cookies Policy or our use of cookies,
      please contact us:
      <h5>By email: rapidconnect-support@connectgenus.com </h5>
      <h3>11.Changes to this Cookies Policy</h3>
      We may update this Cookies Policy from time to time. We will notify you of
      any changes by posting the updated policy on the Platform.
    </div>
  );
};

export default Cookie;