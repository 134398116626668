import React from "react";
import AttentionIcon from "../../assets/Attention.png";
import "./connectYourCRM.css";

export default function GuidePopUp() {
  return (
    <div className="guidePopUp">
      <h4>Attention Please</h4>
      <div className="attentionIcon">
      <img src={AttentionIcon} alt="Attention GIF" loading="lazy" />
      <h5 className="note-text">Please download and go through the <strong>'Guide'</strong> from the next screen, before connecting your contact center with Salesforce</h5>
      </div>
    </div>
  );
}
