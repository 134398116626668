import React, { useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MainTheme from "../mainTemplate";
import ModalForm from "../../utilities/modal";
import Title from "../../utilities/title";
import DetailsForm from "./detailsForm";
import "./addAgents.css";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CSVComponent from "./csvForm";
import {
  mannuallyDetailsContext,
  agentDetailsContext,
  notFoundStatusContext,
} from "../../context";
 
export default function AddAgents() {
  let navigate = useNavigate();
  let agentForm = React.useContext(agentDetailsContext);
  let notFoundStatus = React.useContext(notFoundStatusContext);
  const [agentType, setAgentType] = useState({
    addMannually: agentForm.selectAgentType.addMannually,
    throughCsv: agentForm.selectAgentType.throughCsv,
  });
 
    
  let agentDetails = React.useContext(mannuallyDetailsContext);
  const [openDetailsForm, setOpenDetailsForm] = useState(false);
  const [openThroughCSVDetailsForm, setOpenThroughCSVDetailsForm] =
    useState(false);
 
    const [loading, setLoading] = useState(false);
    const [showCloseIcon, setShowCloseIcon] = useState(true);
  React.useEffect(() => {
    notFoundStatus.status = false;
  }, []);
 
  React.useEffect(() => {
    if (agentType) {
      agentForm.selectAgentType = agentType;
    }
  }, [agentType]);
 
  const handleOnChange = (type) => {
    if (type === "add-mannually") {
      setOpenDetailsForm(true);
      setAgentType({
        addMannually: true,
        throughCsv: false,
      });
    }
    if (type === "through-csv") {
      setAgentType({
        addMannually: false,
        throughCsv: true,
      });
      navigate("/bulk-user-creation");
    }
  };
 
  const handleCloseDetailsForm = () => {
    setOpenDetailsForm(false);
  };
 
  return (
    <MainTheme>
      <Title title={"Add agents"} />
      <Grid
        container
        spacing={2}
        className="container-section"
        justifyContent="center"
      >
        <Grid item md={12} xs={12}>
          <p>Select an option from below</p>
          <Grid container spacing={2} className="add-agent-sub-div">
            <Grid item md={3} xs={1} />
            <Grid item md={6} xs={10} className="button-container">
              {" "}
              <Button
                variant={"outlined"}
                onClick={() => handleOnChange("add-mannually")}
                fullWidth
                className="custom-button"
              >
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <PersonAddAlt1Icon fontSize="large" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="card-text">Add manually</Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid item md={3} xs={1} />
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <Grid container spacing={1}>
            <Grid md={3} xs={1} />
            <Grid item md={6} xs={10} className="button-container">
              {" "}
              <Button
                variant={"outlined"}
                onClick={() => handleOnChange("through-csv")}
                fullWidth
                className="custom-button"
              >
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <UploadFileIcon fontSize="large" />
                    <Typography className="card-text">
                      Import agents through excel sheet
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid md={3} xs={1} />
          </Grid>
        </Grid>
      </Grid>
      <ModalForm
        title={"Add agent"}
        open={openDetailsForm}
        handleClose={handleCloseDetailsForm}
        setLoading={setLoading}
        loading={loading}
        showCloseIcon={showCloseIcon && !loading}
        
          component={
            <DetailsForm
              handleClose={handleCloseDetailsForm}
              setLoading={setLoading}
              setShowCloseIcon={setShowCloseIcon}
             
              loading={loading}
            />
          }
        />
      {openThroughCSVDetailsForm && <CSVComponent />}
    </MainTheme>
  );
}