import * as React from "react";
import { Snackbar } from "@mui/material";

export default function SnackBar({ label, openSnackBar, close }) {
  const handleClose = () => {
    close(false);
  };

  React.useEffect(() => {
    if (openSnackBar) {
      setTimeout(() => {
        handleClose();
      }, 2000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSnackBar]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={openSnackBar}
      onClose={handleClose}
      message={`${label}`}
    />
  );
}
