import axios from "axios";

const baseURL = process.env.REACT_APP_PUBLIC_URL;
const baseURL_CSV_Download = process.env.REACT_APP_PUBLIC_URL_CSV_DOWNLOAD;
const baseURL_CSV_Upload = process.env.REACT_APP_PUBLIC_URL_CSV_UPLOAD;

const createAxiosInstance = (url) => axios.create({
  baseURL: url,
  headers: { "Content-Type": "application/json" },
});

export const axiosInstance = createAxiosInstance(baseURL);
export const axiosInstanceCSVDownload = createAxiosInstance(baseURL_CSV_Download);
export const axiosInstanceCSVUpload = createAxiosInstance(baseURL_CSV_Upload);